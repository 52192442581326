import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import config from "../coreFIles/config";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import toast, { Toaster } from "react-hot-toast";
import { InsertPaymentDetailsAction } from "../Action/propertyOwner.action"

const CheckoutForm = (data) => {
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      setProcessing(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: ``,
      },
    });

    if (result.error) {
      setProcessing(false);
      toast.error(result.error.message);
    } else {
      console.log(result);
      if (result.paymentIntent.status === "succeeded") {
        let arr = {
          'subscriptionId': data.data.id,
          'orderId': result.paymentIntent.id,
          'cardNumber': '',
          'receiptUrl': '',
          'payerId': '',
          'paymentMode': 2 // 1-Stripe
        }

        let resultAPI = await InsertPaymentDetailsAction(arr)
        if (resultAPI.success) {
          toast.success(resultAPI.msg)
          setTimeout(() => {
            navigate(`${config.baseUrl}dashboard/profile#subscription`);
          }, 2000);
        } else {
          toast.error(resultAPI.msg)
        }
      } else {
        setProcessing(false);
        toast.error("Payment failed. Go back to application and try again!");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Toaster></Toaster>
      <PaymentElement />

      {processing ? (
        <button
          style={{
            padding: "6px",
            background: "#00EAAE",
            color: "#1D2B39",
            marginTop: "18px",
            borderRadius: "5px",
          }}
          disabled={true}
        >
          Please wait
        </button>
      ) : (
        ""
      )}

      {!processing ? (
        <button
          className='w-100 rounded-1 mt-3 btn btn-primary'
          style={{
            padding: "6px",
            marginTop: "18px",
            borderRadius: "5px",
          }}
          disabled={!stripe}
        >
          Pay Now ( ${data.data.amount} ){" "}
        </button>
      ) : (
        ""
      )}
    </form>
  );
};

export default CheckoutForm;
