import React, { useEffect, useState } from "react";
import config from "../config";
import { Formik } from "formik";
import "./generalCSS/login.css";
import "./generalCSS/generalnavbar.css";
import { SigninSchema } from "../validations/signup";
import Select from "react-select";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  LoginAction,
  getCountryCodeAction, 
  googleLoginAction,
} from "../Action/propertyOwner.action";
import Config from "../coreFIles/config";
import { getPlanPurchaseDetailsAction } from "../Action/user.action";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import axios from "axios";

const UserLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setcountryCode] = useState({
    label: "+592",
    value: "592",
  });
  const [countryoptions, setcountryoptions] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const [RemeberData, setRemeberData] = useState([]);
  const [formLoad, setFormLoad] = useState(false);
  useEffect(() => {
    getCountryCodeAPI();
  }, []);

  useEffect(() => {
    var now = new Date();
    let rememberData = localStorage.getItem("loginData");
    if (rememberData) {
      rememberData = JSON.parse(rememberData);
      if (parseInt(rememberData.expiry) - now.getTime() > 0) {
        rememberData.value.remember = true;
        setRemeberData(rememberData.value);
      } else {
        localStorage.setItem("loginData", "");
      }
    }
    setFormLoad(true);
    getLocation();
  }, []);

  const getLocation = async () => {
    axios
      .get(
        `https://ipapi.co/json/?key=1Z7RbQoN0mVBi7iHQI32JPkUkCFs7DNU1BiOrGjZ7izCB8erBo`
      )
      .then((result) => {
        axios
          .get(`https://ipinfo.io/${result.data.ip}?token=ea220bd92fff15`)
          .then((result) => {
            var userAgent = window.navigator.userAgent,
              platform = window.navigator.platform,
              macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
              windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
              iosPlatforms = ["iPhone", "iPad", "iPod"],
              os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
              os = "Mac OS";
            } else if (iosPlatforms.indexOf(platform) !== -1) {
              os = "iOS";
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
              os = "Windows";
            } else if (/Android/.test(userAgent)) {
              os = "Android";
            } else if (!os && /Linux/.test(platform)) {
              os = "Linux";
            }

            console.log({
              ip: result.data.ip,
              city: result.data.city,
              country: result.data.country,
              browsername: os,
            });
          });
      });
  };

  const WriteCookie = async (value) => {
    var now = new Date();
    var minutes = 30;
    const item = {
      value: value,
      expiry: now.getTime() + minutes * 60 * 1000,
    };
    localStorage.setItem("loginData", JSON.stringify(item));
  };

  const getCountryCodeAPI = async () => {
    let arry = [];
    let res = await getCountryCodeAction();
    if (res.success) {
      for (let x in res.data) {
        let obj = {};
        obj.label = `+${res.data[x].phonecode}`;
        obj.value = res.data[x].phonecode;
        arry.push(obj);
      }
      setcountryoptions(arry);
    }
  };

  const responseGoogle = async (response) => {
    try {
      const res = await googleLoginAction({
        firstName: response.given_name,
        lastName: response.family_name,
        email: response.email,
        registrationType: 2,
        isEmailVerify: 1,
        userType: 1,
      });

      if (res.success === true) {
        console.log(res);
        Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
        setLoginData(res.data);
        setTimeout(() => {
          // navigate(`${config.baseurl}dashboard`);
          console.log(
            'localStorage.getItem("PropertDetail")',
            localStorage.getItem("PropertDetail")
          );

          window.location.href = `${config.baseurl}dashboard/getstarted`;
        }, 2000);
        toast.success(res.msg);
      }
    } catch (err) {
      toast.error(err.response);
    }
  };

  const responseFacebook = async (response) => {
    console.log(response);
    try {
      const res = await googleLoginAction({
        firstName: response.data.first_name,
        email: response.data.email,
        mobileNumber: null,
        registrationType: 3,
        isEmailVerify: 1,
        userType: 1,
      });
      if (res.success === true) {
        console.log(res);
        Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
        setLoginData(res.data);
        setTimeout(() => {
          // navigate(`${config.baseurl}dashboard`);
          window.location.href = `${config.baseurl}dashboard/getstarted`;
        }, 500);
        toast.success(res.msg);
      }
    } catch (err) {
      toast.error(err.response);
    }
  };

  const SubmitForm = async (formData) => {
    setisLoading(true);
    formData.browsername = navigator.userAgent;
    formData.countryCode = countryCode.value;
    let res = await LoginAction(formData);
    if (res.success) {
      toast.success(res.msg);
      Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
      if (formData.remember) {
        WriteCookie({
          password: formData.password,
          mobileNumber: formData?.mobileNumber,
          countryCode: formData?.countryCode,
        });
      } else {
        localStorage.setItem("loginData", "");
      }

      setTimeout(() => {
        window.location.href = `${config.baseurl}dashboard/getstarted`;
      }, 2000);
    } else {
      toast.error(res.msg);
      setisLoading(false);
    }
  };

  const checkPlanDetails = async () => {
    let propertyRes = await getPlanPurchaseDetailsAction({ type: 1 }); // Type: 1 For Property, 2 For Vehicle
    let vehicleRes = await getPlanPurchaseDetailsAction({ type: 2 });
    if (propertyRes.success || vehicleRes.success) {
      if (
        propertyRes.data.totalPlanListing.totalListing == 0 &&
        vehicleRes.data.totalPlanListing.totalListing == 0
      ) {
        setTimeout(() => {
          window.location.href = `${config.baseurl}subscriptionplan`;
        }, 2000);
      } else {
        setTimeout(() => {
          window.location.href = `${config.baseurl}dashboard/getstarted`;
        }, 2000);
      }
    } else {
      window.location.href = `${config.baseurl}subscriptionplan`;
    }
  };

  const SelectStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#cf5416" : "#fff",
      minWidth: "63px",
    }),

    control: (defaultStyles, state) => ({
      ...defaultStyles,
      height: "20px",
      minHeight: "30px",
      borderColor: "#E9E9E9",
      minWidth: "63px",
      borderRadius: "0",
      backgroundColor: "#E9E9E9",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      height: "30px",
      margin: "0px",
    }),
  };

  return (
    <>
      <section className="login-section pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Toaster />
          <Row className="justify-content-center ">
            <Col xl={6} lg={6}>
              <div className="login-container">
                <div className="login-form">
                  <div className="login-logo">
                    <Link to={`${config.baseurl}`}>
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                    </Link>
                    <h3 className="pt-4 pb-4">
                      <b>LOGIN</b>
                    </h3>
                  </div>
                  {formLoad && (
                    <Formik
                      initialValues={{
                        mobileNumber: RemeberData?.mobileNumber,
                        countryCode: RemeberData?.countryCode,
                        password: RemeberData?.password,
                        remember: RemeberData?.remember,
                      }}
                      validationSchema={SigninSchema}
                      onSubmit={(values) => {
                        SubmitForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <Row>
                              <Col xl={12} lg={12}>
                                <div className="mb-3">
                                  <InputGroup className="reactSelect position-relative" onKeyPress={(event) => {
                                    if (
                                      !/^\d*[]?\d{0,1}$/.test(event.key)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }} >
                                    <Select
                                      aria-label="countryCode"
                                      name="countryCode"
                                      onBlur={handleBlur}
                                      options={countryoptions}
                                      value={countryCode}
                                      onChange={setcountryCode}
                                      labelledBy="Country Code"
                                      styles={SelectStyles}

                                    />

                                    <Form.Control
                                      placeholder="Enter phone number"
                                      aria-label="mobileNumber"
                                      name="mobileNumber"
                                      onKeyPress={(event) => {
                                        if (
                                          !/^\d*[]?\d{0,1}$/.test(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.mobileNumber}
                                    />
                                  </InputGroup>
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="text-danger"
                                  >
                                    {errors.mobileNumber &&
                                      touched.mobileNumber && (
                                        <div>{errors.mobileNumber}</div>
                                      )}
                                  </Form.Text>
                                </div>
                              </Col>

                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    aria-label="password"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                  />
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="text-danger"
                                  >
                                    {errors.password && touched.password && (
                                      <div>{errors.password}</div>
                                    )}
                                  </Form.Text>
                                </Form.Group>
                              </Col>

                              <Col xl={12} lg={12}>
                                <Row className=" justify-content-between">
                                  <Col lg={6} md={6} sm={6}>
                                    <div>
                                      <Form.Group
                                        className="mb-2"
                                        controlId="formBasicCheckbox"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          id="default-checkbox"
                                          label="Remember Me"
                                          name="remember"
                                          checked={values.remember}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.remember}
                                        />
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    className="text-right"
                                  >
                                    <div className="mb-2">
                                      <Link
                                        to={`${config.baseurl}forgetpassword`}
                                        className="text-decoration-none text-black"
                                      >
                                        Forgot Password
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={12} lg={12}>
                                {isLoading ? (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="w-100"
                                  >
                                    Processing
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100"
                                  >
                                    SIGN IN
                                  </Button>
                                )}
                              </Col>

                              <Col xl={12} lg={12} className="text-center">
                                <div className="social-button text-center mt-4 mb-4">
                                  {/* <GoogleOAuthProvider
                                    clientId="1018403538313-1u3n5j591qoqcq9pvt0fuvopd9irj0p0.apps.googleusercontent.com"
                                    className="gmail"
                                  > */}
                                  <GoogleOAuthProvider
                                    clientId={Config.GOOGLE_CLIENT_ID}
                                    className="gmail"
                                  >
                                    <GoogleLogin
                                      onSuccess={(credentialResponse) => {
                                        const credentialResponseDecode =
                                          jwt_decode(
                                            credentialResponse.credential
                                          );
                                        console.log(credentialResponseDecode);
                                        responseGoogle(
                                          credentialResponseDecode
                                        );
                                      }}
                                      onError={() => {
                                        console.log("Login Failed");
                                      }}
                                    />
                                  </GoogleOAuthProvider>

                                  <LoginSocialFacebook
                                    className="facebook"
                                    appId={Config.REACT_APP_FB_APP_ID}
                                    onResolve={(response) => {
                                      console.log("response", response);
                                      setLoginData(response.data);
                                      responseFacebook(response);
                                    }}
                                    onReject={(error) => {
                                      console.log(error, "errt");
                                    }}
                                  >
                                    <FacebookLoginButton />
                                  </LoginSocialFacebook>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                          <div>
                            Don't have an account?{" "}
                            <Link
                              to={`${config.baseurl}signup`}
                              className="text-decoration-none text-primary"
                            >
                              {" "}
                              Signup
                            </Link>
                          </div>
                        </>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="banner-bottom">
        <img src="assets/images/background/bottom_banner.webp" width="100%" />
      </div>
    </>
  );
};

export default UserLogin;
