import React from 'react';
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../css/testimonial.css'

// import required modules
import { Pagination } from 'swiper/modules';
import { FaStar } from "react-icons/fa";

const Browseservice = () => {


    return (
        <div className=''>
            <Swiper
                slidesPerView={'4'}
                spaceBetween={20}
                loop={`true`}
                modules={[]}
                className="mySwiper"
                breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
            >
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs1.svg" />

                        </div>
                        <Card.Body>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center mb-2'>
                                        <div className='me-2'>
                                            <img src="images/t1.svg" className='object-fit-contain' />
                                        </div>
                                        <div>
                                            <h6 className='mb-0 text-wrap'>Mark Smith</h6>
                                        </div>
                                    </div>
                                    <div className='mb-2'>
                                        <div className="rating text-wrap">
                                            <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>
                                        </div>
                                    </div>

                                </div>
                                <div>

                                    <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                    <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                                </div>


                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs2.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>

                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs3.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>

                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs4.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>

                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs4.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs4.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs4.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <div className=''>
                            <img src="images/bs4.svg" />

                        </div>
                        <Card.Body>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center mb-2'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0'>Mark Smith</h6>

                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <div className="rating">
                                        <span className="text-wrapper text-green fw-border fw-bolder small"><FaStar style={{marginTop: "-4px"}} />&nbsp;&nbsp;4.5</span>&nbsp;&nbsp;<small className="x-small">(145)</small>

                                    </div>
                                </div>

                            </div>
                            <div>
                                <h5 className='small fw-bolder mb-1'>City Central Hotel</h5>
                                <p className='x-small mb-0'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>

                        </Card.Body>
                    </Card>
                </SwiperSlide>

            </Swiper>
        </div>
    );
}

export default Browseservice;
