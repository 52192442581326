import React from "react";
import config from "../../config";
import config1 from "../../coreFIles/config";
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  Offcanvas,
  Button,
} from "react-bootstrap";
import "../commanComponents/componentCSS/usernavbar.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  getUserProfileAction,
  logoutAction,
  getPlanPurchaseDetailsAction,
} from "../../Action/user.action";
import { useNavigate } from "react-router-dom";

let urlParameter = window.location.pathname.split("/").pop();

const Dashboardnavbar = () => {
  const [userProfile, setUserProfile] = useState([]);
  const navigate = useNavigate();
  const [navData, setNavData] = useState("");

  const loginData = !Cookies.get("loginSuccessPropertyOwner")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));
  if (!loginData || loginData == "") {
    window.location.href = `${config.baseurl}`;
  }

  useEffect(() => {
    getProfileAPI();
    getPlanPurchaseDetailsAPI();
  }, []);

  const getProfileAPI = async () => {

    let res = await getUserProfileAction();
    console.log(res.data.totalUnreadNotification, "llll");
    if (res.success) {
      setUserProfile(res.data);
    }
  };

  const getPlanPurchaseDetailsAPI = async () => {
    let propertyRes = await getPlanPurchaseDetailsAction({ type: 1 }); // Type: 1 For Property, 2 For Vehicle
    let vehicleRes = await getPlanPurchaseDetailsAction({ type: 2 });
    if (propertyRes.success || vehicleRes.success) {
      if (
        propertyRes.data.totalPlanListing.totalListing == 0 &&
        vehicleRes.data.totalPlanListing.totalListing == 0
      ) {
        window.location.href = `${config.baseurl}subscriptionplan`;
      }
    } else {
      window.location.href = `${config.baseurl}subscriptionplan`;
    }
  };

  const redirectUrl = async (page) => {
    window.location.href = page;
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const logout = async () => {
    // await logoutAction();
    Cookies.remove("loginSuccessPropertyOwner");
    window.location.href = `${config.baseurl}`;
  };

  const naviagteToUrl = async (data) => {
    setNavData(data);
    navigate(
      `${config.baseurl}${config.loginurl}/${data === "prop" ? "calender" : "CalenderVehicle"
      } `,
      { state: data }
    );
    setTimeout(() => {
      window.location.reload();
    });
  };
  return (
    <>
      {["xl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-white pt-2 pb-2 ">
          <Container>
            <Navbar.Brand href="#">
              <img src="assets/images/logo/logo.webp" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src="assets/images/logo/logo.webp" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {/* <Nav className="justify-content-end flex-grow-1 ">
                  <Nav.Link className={urlParameter == 'dashboard' ? 'active' : ''} href={`${config.baseurl}${config.loginurl}`}>Today</Nav.Link>
                  <Nav.Link className={urlParameter == 'reservation' ? 'active' : ''} href={`${config.baseurl}${config.loginurl}/reservation`}>Reservation</Nav.Link>
                  <Nav.Link className={urlParameter == 'chat' ? 'active' : ''} href={`${config.baseurl}${config.loginurl}/chat`}>Inbox</Nav.Link>
                  <Nav.Link className={urlParameter == 'calender' ? 'active' : ''} href={`${config.baseurl}${config.loginurl}/calender`}>Calendar</Nav.Link>
                  <Nav.Link className={urlParameter == 'listingproperty' ? 'active' : ''} href={`${config.baseurl}${config.loginurl}/listingproperty`}>Listings</Nav.Link>
                  <Nav.Link className={urlParameter == 'transaction' ? 'active' : ''} href={`${config.baseurl}${config.loginurl}/transaction`}>Transaction</Nav.Link>
                  {/* <Nav.Link><Button onClick={() => redirectUrl('userdashboard/yourbooking')} variant='primary' className='rounded-5 px-3'>Switch To Hosting</Button></Nav.Link> */}
                {/* </Nav> */}
                {navData == "prop" ||
                  urlParameter == "dashboard" ||
                  urlParameter == "reservation" ||
                  urlParameter == "chat" ||
                  urlParameter == "calender" ||
                  urlParameter == "listingproperty" ||
                  urlParameter == "transaction" ||
                  urlParameter == "profile" ||
                  urlParameter == "" ? (
                  <>
                    <Nav className="justify-content-end flex-grow-1 ">
                      <Nav.Link
                        className={urlParameter == "dashboard" ? "active" : ""}
                        href={`${config.baseurl}${config.loginurl}`}
                      >
                        Today
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "reservation" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/reservation`}
                      >
                        Reservation
                      </Nav.Link>
                      <Nav.Link
                        className={urlParameter == "chat" ? "active" : ""}
                        href={`${config.baseurl}${config.loginurl}/chat`}
                        style={{display:"flex", flexDirection:"row", gap:"5px"}}
                      >
                        Inbox
                        {userProfile?.totalSupportHostUnreadMsg > 0 ? <div className='msg'>{userProfile?.totalSupportHostUnreadMsg}</div> : ""}
                      </Nav.Link>
                      <Nav.Link
                        className={urlParameter == "calender" ? "active" : ""}
                        onClick={() => naviagteToUrl("prop")}
                      >
                        Calendar
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "listingproperty" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/listingproperty`}
                      >
                        Listings
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "transaction" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/transaction`}
                      >
                        Transaction
                      </Nav.Link>
                      {/* {/ <Nav.Link><Button onClick={() => redirectUrl('userdashboard/yourbooking')} variant='primary' className='rounded-5 px-3'>Switch To Hosting</Button></Nav.Link> /} */}
                    </Nav>
                  </>
                ) : (
                  <>
                    <Nav className="justify-content-end flex-grow-1 ">
                      <Nav.Link
                        className={
                          urlParameter == "vehicleDashboard" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/vehicleDashboard`}
                      >
                        Today
                      </Nav.Link>

                      <Nav.Link
                        className={
                          urlParameter == "vehicleReservation" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/vehicleReservation`}
                      >
                        Reservation
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "vehcileChat" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/vehcileChat`}
                      >
                        Inbox
                        {userProfile?.getSupportHostUnreadMsgForVehicle > 0 ? <span className='msg'>{userProfile?.getSupportHostUnreadMsgForVehicle}</span> : ""}
                      </Nav.Link>
                      <Nav.Link
                        className={urlParameter == "CalenderVehicle" ? "active" : ""}
                        onClick={() => naviagteToUrl("vehicle")}
                      >
                        Calendar
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "listingVehicle" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/listingVehicle`}
                      >
                        Listings
                      </Nav.Link>
                      <Nav.Link
                        className={
                          urlParameter == "vehicleTransaction" ? "active" : ""
                        }
                        href={`${config.baseurl}${config.loginurl}/vehicleTransaction`}
                      >
                        Transaction
                      </Nav.Link>
                      {/* {/ <Nav.Link><Button onClick={() => redirectUrl('userdashboard/yourbooking')} variant='primary' className='rounded-5 px-3'>Switch To Hosting</Button></Nav.Link> /} */}
                    </Nav>
                  </>
                )}
                <Nav className="justify-content-end flex-grow-1">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="default"
                      id="dropdown-basic"
                      className="border-0"
                    >
                      {!userProfile?.profile ||
                        userProfile?.profile == undefined ||
                        userProfile?.profile == null ? (
                        <img src="assets/images/profile-img.png" width="32px" />
                      ) : (
                        <img
                          src={userProfile?.profile}
                          width="40px"
                          height="40px"
                          style={{ objectFit: "cover", borderRadius: "50%" }}
                          className=""
                        />
                      )}
                      &nbsp;{" "}
                      {userProfile?.firstName ? userProfile?.firstName : "NA"}{" "}
                      {userProfile?.lastName ? userProfile?.lastName : ""}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#" onClick={() => redirectUrl("")}>
                        Home
                      </Dropdown.Item>

                      <Dropdown.Item
                        href="#"
                        onClick={() =>
                          redirectUrl("userdashboard/propertyBooking")
                        }
                      >
                        Switch To Traveling{" "}
                      </Dropdown.Item>

                      <Dropdown.Item
                        href="#"
                        onClick={() =>
                          redirectUrl(
                            `${`${config.baseurl}dashboard/profile#notification`}`
                          )
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          Notification{" "}
                          {userProfile?.totalUnreadNotification > 0 ? (
                            <span className="msg">
                              {userProfile?.totalUnreadNotification}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Dropdown.Item>



                      <Dropdown.Item
                        href="#"
                        onClick={() =>
                          redirectUrl(
                            `${`${config.baseurl}dashboard/profile#subscription`}`
                          )
                        }
                      >
                        Subscription
                      </Dropdown.Item>

                      <Dropdown.Item
                        href={`${config.baseurl}dashboard/profile`}
                      >
                        Profile
                      </Dropdown.Item>

                      <Dropdown.Item
                        href="#"
                        onClick={() =>
                          redirectUrl(
                            `${`${config.baseurl}dashboard/profile#login_security`}`
                          )
                        }
                      >
                        Login & Security
                      </Dropdown.Item>

                      <Dropdown.Item
                        href="#"
                        onClick={() =>
                          redirectUrl(
                            `${`${config.baseurl}dashboard/profile#payment_payout`}`
                          )
                        }
                      >
                        Payouts
                      </Dropdown.Item>

                      <Dropdown.Item href={`${config.baseurl}dashboard/ticketmanagement`}>
                        <div className='d-flex justify-content-between align-items-center'>Get Help {userProfile?.totalSupportUnreadMsg > 0 ? <span className='msg'>{userProfile?.totalSupportUnreadMsg}</span> : ""}</div></Dropdown.Item>

                      <Dropdown.Item onClick={logout} className="">
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Dashboardnavbar;
