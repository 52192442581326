import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../directives/Header"
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import ListMenuButton from "./listMenu";
import Select from "react-select";
import { toast, Toaster } from "react-hot-toast";
import { getEmployeeListAction, getCountryCodeAction, updateEmployeeAction, addEmployeeAPI } from '../../../../Action/propertyOwner.action'
import { GoPencil } from "react-icons/go";

const customStyles = {
    rows: {
        style: {
            minHeight: '70px', // override the row height
        },
    },
    headCells: {
        style: {
            minHeight: '60px',
            paddingLeft: '20px', // override the cell padding for head cells
            paddingRight: '20px',
            background: '#FFF8F4',
        },
    },
    cells: {
        style: {
            paddingLeft: '20px', // override the cell padding for data cells
            paddingRight: '20px',
        },
    },
};

const Employee = () => {

    const [employeeList, setEmployeeList] = useState([]);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [countryCode, setcountryCode] = useState({ label: "+592", value: "592" });
    const [countryoptions, setcountryoptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getEmployeeListAPI();
        getCountryCodeAPI();
    }, []);

    const getEmployeeListAPI = async () => {
        let res = await getEmployeeListAction();
        if (res.success) {
            setEmployeeList(res.data || []);
        }
    }

    const getCountryCodeAPI = async () => {
        let arry = []
        let res = await getCountryCodeAction();
        if (res.success) {
            for (let x in res.data) {
                let obj = {}
                obj.label = `+${res.data[x].phonecode}`
                obj.value = res.data[x].phonecode
                arry.push(obj)
            }
            setcountryoptions(arry)
        }
    };

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => `${row.firstName} ${row.lastName}`,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Mobile Number',
            selector: row => `${row.countryCode} ${row.mobileNumber}`,
        },
        {
            name: 'Action',
            selector: row => (
                <Button variant={`primary`}
                    className="btn-sm h-auto  rounded-5 " onClick={() => handleEdit(row)}>
                    <GoPencil />
                    &nbsp;Edit
                </Button>
            )
        }
    ];

    const modelClose = () => {
        setFormData({})
        setIsModelOpen(false);
    }

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setFormData((old) => {
            return { ...old, [name]: value };
        });
        if (value.trim() !== "") {
            setErrors((oldErrors) => ({ ...oldErrors, [name]: "" }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.title) newErrors.title = "Title is required.";
        if (!formData.firstName) newErrors.firstName = "First name is required.";
        if (!formData.lastName) newErrors.lastName = "Last name is required.";
        if (!formData.displayName) newErrors.displayName = "Display name is required.";
        if (!formData.email) newErrors.email = "Email is required.";
        if (!formData.mobileNumber) newErrors.mobileNumber = "Mobile number is required.";
        if (!formData.streetAddress) newErrors.streetAddress = "Address is required.";
        if (!formData.city) newErrors.city = "City is required.";
        if (!formData.postalCode) newErrors.postalCode = "Postal code is required.";
        if (!formData.country) newErrors.country = "Country is required.";
        if (!formData.dateOfBirth) newErrors.dateOfBirth = "DOB is required.";
        if (!formData.gender) newErrors.gender = "Gender is required.";
        if (!formData.notes) newErrors.notes = "Notes is required.";
        if (!formData.employeeId) newErrors.employeeId = "Employee id is required.";
        if (!formData.hireDate) newErrors.hireDate = "Hire date is required.";
        if (!formData.releasedDate) newErrors.releasedDate = "Released date is required.";
        if (!formData.expenseRate) newErrors.expenseRate = "Expense rate is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            setIsLoading(true)
            let data = {
                ...formData,
                countryCode: countryCode.value,
            };

            if (isEdit) {
                let res = await updateEmployeeAction(data);
                if (res.success) {
                    toast.success(res.msg);
                    getEmployeeListAPI();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            } else {
                let res = await addEmployeeAPI(data);
                if (res.success) {
                    toast.success(res.msg);
                    getEmployeeListAPI();
                    modelClose();
                    setFormData({})
                } else {
                    toast.error(res.msg);
                }
                setIsLoading(false)
            }
        } catch (error) {
            toast.error('Something went wrong please try again after some time.');
        }
    };

    const handleEdit = (employeeDetails) => {
        setFormData(employeeDetails);
        setcountryCode({ label: `+${employeeDetails.countryCode}`, value: employeeDetails.countryCode });
        setIsEdit(true);
        setIsModelOpen(true);
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className="">
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <div className="d-flex profileMobile align-items-center">
                                <img
                                    src="images/p1.png"
                                    width="40px"
                                    height="40px"
                                    style={{ objectFit: "cover", borderRadius: "50%" }}
                                    className="me-2"
                                />

                                &nbsp;{" "}
                                <div className="profileContent d-flex align-items-center">
                                    <div className="">
                                        <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p>
                                        <div>
                                            <h5 className="mb-0 me-3 fw-bold">Hey Mark Smith</h5>
                                        </div>
                                    </div>

                                    <img src="images/icon/hand.svg" width={`37px`} />
                                </div>
                            </div>

                        </Col>
                        <Col lg={7} sm={12} xs={12} className=" text-right">
                            <Link to={`${config.baseurl}servicedashboard`}>
                                <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Service Dashboard
                                </Button>
                            </Link>
                            <Link to={`${config.baseurl}vehicledashboard`}>
                                <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Vehicle Dashboard
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className=""><h4 className="fw-bolder mb-4">Employee</h4></div>
                        <div className="text-end">
                            <Button className=" rounded-5  px-4 px-xs-1 mb-4 mobileBtn" onClick={setIsModelOpen}> New Employee </Button>
                        </div>
                    </div>
                    <Card.Body className="p-0">
                        <div className="">
                            <Row className="">
                                <Col lg={12} xs={12}>
                                    <div className="mb-4">
                                        <DataTable
                                            columns={columns}
                                            data={employeeList}
                                            customStyles={customStyles}
                                            theme="solarized"
                                            pagination
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card.Body>

                    <Modal
                        show={isModelOpen}
                        onHide={() => modelClose(false)}
                        dialogClassName="modal-90w modal-xl"
                        aria-labelledby="example-custom-modal-styling-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <b>Add Employee</b>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="">
                                <Col lg={12} className="mb-3">
                                    <Card className="border-0 bg-white p-3">
                                        <Card.Header className="border-0 mb-0">
                                            <div className="">
                                                <h4 className="mb-0 fw-bolder">Name & Contact </h4>
                                            </div>

                                        </Card.Header>
                                        <Card.Body className="p-0 mb-3">
                                            <div className="">
                                                <Row className="mt-2">
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Title</Form.Label>
                                                            <Form.Control type="text" name="title" onChange={inputHandler} value={formData.title || ''} />
                                                            {errors.title && <small className="text-danger">{errors.title}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">First Name</Form.Label>
                                                            <Form.Control type="text" name="firstName" onChange={inputHandler} value={formData.firstName || ''} />
                                                            {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Last Name </Form.Label>
                                                            <Form.Control type="text" name="lastName" onChange={inputHandler} value={formData.lastName || ''} />
                                                            {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Employee Display Name </Form.Label>
                                                            <Form.Control type="text" name="displayName" onChange={inputHandler} value={formData.displayName || ''} />
                                                            {errors.displayName && <small className="text-danger">{errors.displayName}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Email </Form.Label>
                                                            <Form.Control type="text" name="email" onChange={inputHandler} value={formData.email || ''} />
                                                            {errors.email && <small className="text-danger">{errors.email}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Country Code Number </Form.Label>
                                                            <Select
                                                                aria-label="countryCode"
                                                                name="countryCode"
                                                                options={countryoptions}
                                                                value={countryCode}
                                                                onChange={setcountryCode}
                                                                labelledBy="Country Code"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group className="" controlId="formBasicEmail">
                                                            <Form.Label className="text-light-grey mb-1">Mobile Number </Form.Label>
                                                            <Form.Control type="text" name="mobileNumber" onChange={inputHandler} value={formData.mobileNumber || ''} onKeyPress={(event) => {
                                                                if (
                                                                    !/^\d*[]?\d{0,1}$/.test(event.key)
                                                                ) {
                                                                    event.preventDefault();
                                                                }
                                                            }} />
                                                            {errors.mobileNumber && <small className="text-danger">{errors.mobileNumber}</small>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Card.Body>
                                        <Card className="border-0 bg-white p-0 mb-3">
                                            <Card.Header className="border-0 mb-0">
                                                <div className="">
                                                    <h4 className="mb-0 fw-bolder">Address </h4>
                                                </div>

                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="">
                                                    <Row className="mt-2">
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Street Address</Form.Label>
                                                                <Form.Control type="text" name="streetAddress" onChange={inputHandler} value={formData.streetAddress || ''} />
                                                                {errors.streetAddress && <small className="text-danger">{errors.streetAddress}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">City </Form.Label>
                                                                <Form.Control type="text" name="city" onChange={inputHandler} value={formData.city || ''} />
                                                                {errors.city && <small className="text-danger">{errors.city}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Postal Code </Form.Label>
                                                                <Form.Control type="text" name="postalCode" onChange={inputHandler} value={formData.postalCode || ''} />
                                                                {errors.postalCode && <small className="text-danger">{errors.postalCode}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Country</Form.Label>
                                                                <Form.Control type="text" name="country" onChange={inputHandler} value={formData.country || ''} />
                                                                {errors.country && <small className="text-danger">{errors.country}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Date of Birth</Form.Label>
                                                                <Form.Control type="date" name="dateOfBirth" onChange={inputHandler} value={formData.dateOfBirth || ''} />
                                                                {errors.dateOfBirth && <small className="text-danger">{errors.dateOfBirth}</small>}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Gender </Form.Label>
                                                                <Form.Select aria-label="Default select example" onChange={inputHandler} name="gender" value={formData.gender || ''} >
                                                                    <option value="0">Select Gender</option>
                                                                    <option value="1">Male</option>
                                                                    <option value="2">Female</option>
                                                                    <option value="3">Other</option>
                                                                </Form.Select>
                                                                {errors.gender && <small className="text-danger">{errors.gender}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={12} className="mb-4">
                                                            <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Notes</Form.Label>
                                                                <Form.Control as="textarea" rows={3} className="h-auto" value={formData.notes || ''} onChange={inputHandler} name="notes" />
                                                                {errors.notes && <small className="text-danger">{errors.notes}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </Card.Body>
                                        </Card>
                                        <Card className="border-0 bg-white p-0 mb-3">
                                            <Card.Header className="border-0 mb-0">
                                                <div className="">
                                                    <h4 className="mb-0 fw-bolder">Additional Info </h4>
                                                </div>

                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="">
                                                    <Row className="mt-2">
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Employee ID</Form.Label>
                                                                <Form.Control type="text" name="employeeId" onChange={inputHandler} value={formData.employeeId || ''} />
                                                                {errors.employeeId && <small className="text-danger">{errors.employeeId}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Hire Date </Form.Label>
                                                                <Form.Control type="date" name="hireDate" onChange={inputHandler} value={formData.hireDate || ''} />
                                                                {errors.hireDate && <small className="text-danger">{errors.hireDate}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Released Date </Form.Label>
                                                                <Form.Control type="date" name="releasedDate" onChange={inputHandler} value={formData.releasedDate || ''} />
                                                                {errors.releasedDate && <small className="text-danger">{errors.releasedDate}</small>}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Expense Rate </Form.Label>
                                                                <Form.Control type="text" name="expenseRate" onChange={inputHandler} value={formData.expenseRate || ''} />
                                                                {errors.expenseRate && <small className="text-danger">{errors.expenseRate}</small>}
                                                            </Form.Group>
                                                        </Col>

                                                    </Row>
                                                </div>

                                            </Card.Body>
                                        </Card>
                                        <Col lg={12} className="text-center">
                                            <div>
                                                <Link to={`#`}>
                                                    {isLoading ?
                                                        <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" disabled>
                                                            Processing...
                                                        </button>
                                                        :
                                                        <button className="rounded-5 me-2 px-4 px-xs-1 mb-3 btn btn-primary" onClick={handleSubmit}>
                                                            {isEdit ? 'Update Employee' : 'Save Employee'}
                                                        </button>
                                                    }
                                                </Link>
                                            </div>
                                        </Col>
                                    </Card>
                                </Col>
                            </div>
                        </Modal.Body>
                    </Modal>

                </Container>
            </section>
        </div >
    );
};

export default Employee;
