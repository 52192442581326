import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import Header from "../../directives/Header"
import RevenueChart from "../RevenueChart"
import Testimonial from "../testimonial"
import Browseservice from "../browseservice";
import "../../css/staffdashboard.css"



const Service = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);

    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };

    const navigateRoute = () => {
        setTimeout(() => {
            navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
            window.location.reload();
        });
    };
    const columns = [
        {
            name: 'Vehicle Name',
            selector: row => row.name,
        },
        {
            name: 'Vehicle Type',
            selector: row => row.vehicletype,
        },
        {
            name: 'Passenger Name',
            selector: row => row.passengername,
        },
        {
            name: 'Pickup Location',
            selector: row => row.pickuplocation,
        },
        {
            name: 'Drop Off',
            selector: row => row.dropoff,
        },
        {
            name: 'Booked',
            selector: row => row.booked,
        },
        {
            name: 'Payment',
            selector: row => row.payment,
        },
        {
            name: 'Status',
            selector: row => row.status,
        },

    ];

    const data = [
        {
            id: 1,
            name: 'Toyota Fortuner',
            vehicletype: 'SUV',
            passengername: 'Mark Smith',
            pickuplocation: 'Manhattan, NYC',
            dropoff: 'Time Squire',
            booked: '2 Person',
            payment: 'Paid',
            status: 'Booked',
        },
        {
            id: 2,
            name: 'Toyota Fortuner',
            vehicletype: 'SUV',
            passengername: 'Mark Smith',
            pickuplocation: 'Manhattan, NYC',
            dropoff: 'Time Squire',
            booked: '2 Person',
            payment: 'Paid',
            status: 'Booked',
        },
        {
            id: 3,
            name: 'Toyota Fortuner',
            vehicletype: 'SUV',
            passengername: 'Mark Smith',
            pickuplocation: 'Manhattan, NYC',
            dropoff: 'Time Squire',
            booked: '2 Person',
            payment: 'Paid',
            status: 'Booked',
        },
    ]


    return (
        <div className="sstaffdashboard bg-pink">
            <Header />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row className="">
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <div className="d-flex profileMobile align-items-center">
                                <img
                                    src="images/p1.png"
                                    width="40px"
                                    height="40px"
                                    style={{ objectFit: "cover", borderRadius: "50%" }}
                                    className="me-2"
                                />

                                &nbsp;{" "}
                                <div className="profileContent d-flex align-items-center">
                                    <div className="">
                                        <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p>
                                        <div>
                                            <h5 className="mb-0 me-3 fw-bold">Hey Mark Smith</h5>
                                        </div>
                                    </div>

                                    <img src="images/icon/hand.svg" width={`37px`} />
                                </div>
                            </div>

                        </Col>
                        <Col lg={7} sm={12} xs={12} className=" text-right">
                            <Link to={`${config.baseurl}servicedashboard`}>
                                <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Service Dashboard
                                </Button>
                            </Link>
                            <Link to={`${config.baseurl}vehicledashboard`}>
                                <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Vehicle Dashboard
                                </Button>
                            </Link>
                        </Col>

                        <Col xl={12} lg={12}>
                            <div className="">
                                <Row>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                                        <Card className="border-0">
                                            <Card.Body className="">
                                                <div className="d-flex align-items-center ">
                                                    <div className="me-4">
                                                        <img
                                                            src="images/icon/star.svg"
                                                            width="36px"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="mb-1 text-light-grey">Rating</p>
                                                        <h4>
                                                            <div className="rating text-wrap">
                                                                <span className="text-wrapper text-green fw-border fw-bolder "><FaStar style={{ marginTop: "-4px" }} />&nbsp;4.5</span>&nbsp;<small className="x-small">(145)</small>
                                                            </div>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                                        <Card className="border-0">

                                            <Card.Body className="">
                                                <div className="d-flex align-items-center ">
                                                    <div className="me-4">
                                                        <img
                                                            src="images/icon/suitcase.svg"
                                                            width="36px"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="mb-1 text-light-grey">Total Jobs</p>
                                                        <h4>
                                                            <b>
                                                                390
                                                            </b>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                                        <Card className="border-0">

                                            <Card.Body className="">
                                                <div className="d-flex align-items-center ">
                                                    <div className="me-4">
                                                        <img
                                                            src="images/icon/doc.svg"
                                                            width="36px"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="mb-1 text-light-grey">Total Requests</p>
                                                        <h4>
                                                            <b>
                                                                350
                                                            </b>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </Card.Body>

                                        </Card>
                                    </Col>
                                    <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                                        <Card className="border-0">

                                            <Card.Body className="">
                                                <div className="d-flex align-items-center ">
                                                    <div className="me-4">
                                                        <img
                                                            src="images/icon/circledollar.svg"
                                                            width="36px"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p className="mb-1 text-light-grey">Total Quotations </p>
                                                        <h4>
                                                            <b>
                                                                145
                                                            </b>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </Card.Body>

                                        </Card>
                                    </Col>
                                </Row>

                            </div>
                            <div className="graph">
                                <Row className="mt-2" >
                                    <Col xl={8} lg={12} className="mb-4">
                                        <div>
                                            <Card className="border-0">
                                                <div className="overlay-box">
                                                    <Button variant="primary" className="px-4 rounded-5">View Analytics</Button>
                                                </div>
                                                <Card.Body>
                                                    <RevenueChart />
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Col>
                                    <Col xl={4} lg={12} className="mb-4">
                                        <div className="staff_upcoming_booking">
                                            <Card className="border-0">
                                                <Card.Body>
                                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                                        <div><h4 class="mb-0"><b>Recent Quotations</b></h4></div>
                                                        <div> <Button variant="default" className="h-auto rounded-5 border-0 text-primary p-0">View all</Button></div>
                                                    </div>
                                                    <div>
                                                        <ul className="ps-0 list-style-none">
                                                            <li className="border-bottom pb-2">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex w-75 align-items-center">
                                                                        <div className="recentImg">
                                                                            <img src="images/r1.png" className="" height={`45px`} width={`45px`} />

                                                                        </div>
                                                                        <div className="mb-1 small ms-2 pe-2">Mark Smith Requested a new quotation for AC Repair</div>
                                                                    </div>
                                                                    <div className="ps-0">

                                                                        <div className="pt-2">
                                                                            <div className="">

                                                                                <div className="d-flex align-items-center">
                                                                                    <b >$65.50</b>
                                                                                    <Button variant="primary" className="h-auto btn-sm rounded-5 px-3 ms-2">view</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="border-bottom pb-2 pt-2">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex w-75 align-items-center">
                                                                        <div className=" recentImg ">
                                                                            <img src="images/r2.png" className="" height={`45px`} width={`45px`} />

                                                                        </div>
                                                                        <div className=" small ms-2 pe-2">Jacob Roy Requested a new quotation for microwave</div>
                                                                    </div>
                                                                    <div className="ps-0">

                                                                        <div className="pt-2">
                                                                            <div className="">

                                                                                <div className="d-flex align-items-center">
                                                                                    <b >$65.50</b>
                                                                                    <Button variant="primary" className="h-auto btn-sm rounded-5 px-3 ms-2">view</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="border-bottom pb-2 pt-2">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex w-75 align-items-center">
                                                                        <div className=" recentImg">
                                                                            <img src="images/r3.png" className="" height={`45px`} width={`45px`} />

                                                                        </div>
                                                                        <div className=" small ms-2 pe-2">Bershan Shaw Requested a new quotation for cctv</div>
                                                                    </div>
                                                                    <div className="ps-0">
                                                                        <div className="pt-2">
                                                                            <div className="">

                                                                                <div className="d-flex align-items-center">
                                                                                    <b >$65.50</b>
                                                                                    <Button variant="primary" className="h-auto btn-sm rounded-5 px-3 ms-2">view</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>
                                                            <li className="border-bottom pb-2 pt-2">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex w-75 align-items-center">
                                                                        <div className="recentImg">
                                                                            <img src="images/r4.png" className="" height={`45px`} width={`45px`} />
                                                                        </div>
                                                                        <div className=" small ms-2 pe-2">John Doe Requested a new quotation for AC Repair</div>
                                                                    </div>
                                                                    <div className="ps-0">
                                                                        <div className="pt-2">
                                                                            <div className="">
                                                                                <div className="d-flex align-items-center">
                                                                                    <b >$65.50</b>
                                                                                    <Button variant="primary" className="h-auto btn-sm rounded-5 px-3 ms-2">view</Button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li className=" pt-2">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex w-75 align-items-center">
                                                                        <div className="recentImg">
                                                                            <img src="images/r5.png" className="" height={`45px`} width={`45px`} />
                                                                        </div>
                                                                        <div className=" small ms-2 pe-2">Ashly Brook Requested a new quotation for cctv</div>
                                                                    </div>
                                                                    <div className="ps-0">
                                                                        <div className="pt-2">
                                                                            <div className="">

                                                                                <div className="d-flex align-items-center">
                                                                                    <b >$65.50</b>
                                                                                    <Button variant="primary" className="h-auto btn-sm rounded-5 px-3 ms-2">view</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </Card.Body>
                                            </Card>

                                        </div>
                                    </Col>

                                </Row>

                            </div>
                            <div className="testimonial mb-4">
                                <Col lg={12}>
                                    <Card className="border-0 bg-white p-3">
                                        <Card.Header>
                                            <div className="d-flex justify-content-between ">
                                                <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Latest Reviews </h4>&nbsp;<div>(25)</div></div>
                                                <div><Button variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                                            </div>

                                        </Card.Header>
                                        <Card.Body className="pt-0 px-0">
                                            <div className="testimonialSlider">
                                                <Testimonial />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </div>
                            <div className="services">
                                <Col lg={12}>
                                    <Card className="border-0 bg-white p-3">
                                        <Card.Header>
                                            <div className="d-flex justify-content-between ">
                                                <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Browse Services </h4></div>
                                                <div><Button variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                                            </div>

                                        </Card.Header>
                                        <Card.Body className="pt-0 px-0">
                                            <div className="">
                                                <Browseservice />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </div>
    );
};

export default Service;
