import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import "../../commanComponents/componentCSS/dashboard.css";
import "../../commanComponents/componentCSS/vehicledashboard.css";
import { Link } from "react-router-dom";
import config from "../../../config";
import config1 from "../../../coreFIles/config";
import Header from "../directives/Header"
import RevenueChart from "./RevenueChart"
import Testimonial from "./testimonial"
import "../css/staffdashboard.css"



import { useNavigate } from "react-router-dom";
import Browseservice from "./browseservice";

const Dashboard = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add('staffbody');

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('staffbody');
    };
  }, []);


  const navigateRoute = () => {
    setTimeout(() => {
      navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
      window.location.reload();
    });
  };

  return (
    <div className="sstaffdashboard bg-pink">
      <Header />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <Row className="">
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <div className="d-flex profileMobile align-items-center">
                <img
                  src="images/p1.png"
                  width="40px"
                  height="40px"
                  style={{ objectFit: "cover", borderRadius: "50%" }}
                  className="me-2"
                />

                &nbsp;{" "}
                <div className="profileContent d-flex align-items-center">
                  <div className="">
                    <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p>
                    <div>
                      <h5 className="mb-0 me-3 fw-bold">Hey Mark Smith</h5>
                    </div>
                  </div>

                  <img src="images/icon/hand.svg" width={`37px`} />
                </div>
              </div>

            </Col>
            <Col lg={7} sm={12} xs={12} className=" text-right">
              <Link to={`${config.baseurl}servicedashboard`}>
                <Button
                  variant="outline-primary"
                  className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                >
                  Service Dashboard
                </Button>
              </Link>
              <Link to={`${config.baseurl}vehicledashboard`}>
                <Button
                  variant="primary"
                  className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                >
                  Vehicle Dashboard
                </Button>
              </Link>
            </Col>

            <Col xl={12} lg={12}>
              <div className="">
                <Row>
                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Card className="border-0">
                      <Card.Body className="">
                        <div className="d-flex align-items-center ">
                          <div className="me-4">
                            <img
                              src="images/icon/vacant.svg"
                              width="36px"
                            />
                          </div>
                          <div>
                            <p className="mb-1 text-light-grey">Vacant Room</p>
                            <h4>
                              <b>
                                08
                              </b>
                            </h4>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Card className="border-0">

                      <Card.Body className="">
                        <div className="d-flex align-items-center ">
                          <div className="me-4">
                            <img
                              src="images/icon/occupied.svg"
                              width="36px"
                            />
                          </div>
                          <div>
                            <p className="mb-1 text-light-grey">Occupied Room</p>
                            <h4>
                              <b>
                                15
                              </b>
                            </h4>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Card className="border-0">

                      <Card.Body className="">
                        <div className="d-flex align-items-center ">
                          <div className="me-4">
                            <img
                              src="images/icon/cancelled.svg"
                              width="36px"
                            />
                          </div>
                          <div>
                            <p className="mb-1 text-light-grey">Cancelled Room</p>
                            <h4>
                              <b>
                                05
                              </b>
                            </h4>
                          </div>
                        </div>
                      </Card.Body>

                    </Card>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Card className="border-0">

                      <Card.Body className="">
                        <div className="d-flex align-items-center ">
                          <div className="me-4">
                            <img
                              src="images/icon/checkout.svg"
                              width="36px"
                            />
                          </div>
                          <div>
                            <p className="mb-1 text-light-grey">Checked Out Rooms</p>
                            <h4>
                              <b>
                                04
                              </b>
                            </h4>
                          </div>
                        </div>
                      </Card.Body>

                    </Card>
                  </Col>
                </Row>
              </div>
              <div className="graph">
                <Row className="mt-2" >
                  <Col xl={8} lg={12} className="mb-4">
                    <div>
                      <Card className="border-0">
                      <div className="overlay-box">
                                                    <Button variant="primary" className="px-4 rounded-5">View Analytics</Button>
                                                </div>
                        <Card.Body>
                          <RevenueChart />
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={4} lg={12} className="mb-4">
                    <div className="staff_upcoming_booking">
                      <Card className="border-0">
                        <Card.Body>
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div><h4 class="mb-0"><b>Upcoming Booking</b></h4></div>
                            <div> <Button variant="default" className="h-auto rounded-5 border-0 text-primary p-0">View all</Button></div>
                          </div>
                          <div>
                            <ul className="ps-0 list-style-none">
                              <li className="border-bottom pb-2">
                                <Row className="align-items-center">
                                  <Col lg={3} xs={3}>
                                    <div className="pt-2">
                                      <img src="images/property/p1.svg" className="w-100" />
                                    </div>
                                  </Col>
                                  <Col lg={9} xs={9}>
                                    <div className="pt-2">
                                      <h6 className="mb-1 fw-bold">White House Villa</h6>
                                      <h6 className="mb-1 text-primary small">By: John Doe</h6>
                                      <div className="d-flex justify-content-between">
                                        <div className="text-grey small">3 Rooms, 2 Bathrooms</div>
                                        <div className="fw-medium small fst-italic">May 10, 2024</div>

                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                              <li className="border-bottom pb-2">
                                <Row className="align-items-center">
                                  <Col lg={3} xs={3}>
                                    <div className="pt-2">
                                      <img src="images/property/p2.svg" className="w-100" />
                                    </div>
                                  </Col>
                                  <Col lg={9} xs={9}>
                                    <div className="pt-2">
                                      <h6 className="mb-1 fw-bold">Birchwood Bungalow</h6>
                                      <h6 className="mb-1 text-primary small">By: Mark Smith</h6>
                                      <div className="d-flex justify-content-between">
                                        <div className="text-grey small">3 Rooms, 2 Bathrooms</div>
                                        <div className="fw-medium small fst-italic">May 14, 2024</div>

                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                              <li className="border-bottom pb-2">
                                <Row className="align-items-center">
                                  <Col lg={3} xs={3}>
                                    <div className="pt-2">
                                      <img src="images/property/p3.svg" className="w-100" />
                                    </div>
                                  </Col>
                                  <Col lg={9} xs={9}>
                                    <div className="pt-2">
                                      <h6 className="mb-1 fw-bold">Sunnybrook House</h6>
                                      <h6 className="mb-1 text-primary small">By: Emma</h6>
                                      <div className="d-flex justify-content-between">
                                        <div className="text-grey small">3 Rooms, 2 Bathrooms</div>
                                        <div className="fw-medium small fst-italic">May 16, 2024</div>

                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                              <li className="">
                                <Row className="align-items-center">
                                  <Col lg={3} xs={3}>
                                    <div className="pt-2">
                                      <img src="images/property/p4.svg" className="w-100" />
                                    </div>
                                  </Col>
                                  <Col lg={9} xs={9}>
                                    <div className="pt-2">
                                      <h6 className="mb-1 fw-bold">Foxglove Cottage</h6>
                                      <h6 className="mb-1 text-primary small">By: Bershan Shaw</h6>
                                      <div className="d-flex justify-content-between">
                                        <div className="text-grey small">3 Rooms, 2 Bathrooms</div>
                                        <div className="fw-medium small fst-italic">May 20, 2024</div>

                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </li>
                            </ul>
                          </div>

                        </Card.Body>
                      </Card>

                    </div>
                  </Col>

                </Row>

              </div>
              <div className="testimonial mb-4">
                <Col lg={12}>
                  <Card className="border-0 bg-white p-3">
                    <Card.Header>
                      <div className="d-flex justify-content-between ">
                        <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Latest Reviews </h4>&nbsp;<div>(25)</div></div>
                        <div><Button variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                      </div>

                    </Card.Header>
                    <Card.Body className="pt-0 px-0">
                      <div className="testimonialSlider">
                        <Testimonial />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
              <div className="services">
                <Col lg={12}>
                  <Card className="border-0 bg-white p-3">
                    <Card.Header>
                      <div className="d-flex justify-content-between ">
                        <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Browse Services </h4></div>
                        <div><Button variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                      </div>

                    </Card.Header>
                    <Card.Body className="pt-0 px-0">
                      <div className="">
                        <Browseservice />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </div>
  );
};

export default Dashboard;
