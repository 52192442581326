import { OwnerGetRequest, postRequest, OwnerPostRequest ,getRequest ,propertyOwnerRequestForm, OwnerPutRequest} from "../coreFIles/helper";

export const registerSEOAction = (data) => {
  return OwnerPostRequest('registerPreLaunching', data).then(res => { return res.data })
}

export const RegisterAction = (data) => {
  return OwnerPostRequest('registration', data).then(res => { return res.data })
}

export const SmsVerificationAction = (countryCode, mobileNumber, email) => {
  const data = {
    countryCode : countryCode,
    mobileNumber: mobileNumber,
    email:email
  };
  return OwnerPostRequest('smsVerification', data).then(res => { return res.data })
}

export const LoginAction = (data) => {
  return OwnerPostRequest('login', data).then(res => { return res.data })
}

export const getCountryCodeAction = (data) => {
  return OwnerGetRequest('getCountry', data).then(res => { return res.data })
}

export const ForgotPasswordAction = (data) => {
  return OwnerPostRequest('forgotPassword', data).then(res => { return res.data })
}

export const verifyForgetPasswordOTPAction = (data) => {
  return OwnerPostRequest('verifyForgetPasswordOTP', data).then(res => { return res.data })
}

export const ResetPasswordAction = (data) => {
  return OwnerPostRequest('resetpassword', data).then(res => { return res.data })
}

export const getSubscriptionPlanAction = (data)=>{
  return getRequest('getSubscriptionDetail' , data).then(res=>{return res.data})
}
export const getSubscriptionPlanAction1 = (data)=>{
  return OwnerGetRequest('getSubscriptionDetail' , data).then(res=>{return res.data})
}

export const googleLoginAction= (data)=>{
  return OwnerPostRequest('googleLogin' , data).then(res=>{return res.data})
}

export const InsertPaymentDetailsAction=(data)=>{
  return OwnerPostRequest('buySubscription' , data).then(res=>{return res.data})
}

export const getPropertyBookingDetailsAction =(data)=>{
  return OwnerPostRequest('getPropertyBookingDetails' , data).then(res=>{return res.data})
}

export const insertSupportRequestAction =(data)=>{
  return propertyOwnerRequestForm('insertSupportRequest' , data).then(res=>{return res.data})
}

export const getSupportRequestAction =(data)=>{
  return OwnerPostRequest('getSupportRequest' , data).then(res=>{return res.data})
}

export const checkPlanPurchaseAction =(data)=>{
  return OwnerPostRequest('checkPlanPurchase' , data).then(res=>{return res.data})
}

export const paymentIntentAction =(data)=>{
  return OwnerPostRequest('paymentIntent' , data).then(res=>{return res.data})
}

export const getRecentChatAction =(data)=>{
  return OwnerGetRequest('getRecentChat' , data).then(res=>{return res.data})
}

export const getSupportChatDetailsAction =(data)=>{
  return OwnerPostRequest('getSupportChatDetails' , data).then(res=>{return res.data})
}

export const insertChatMsg =(data)=>{
  return OwnerPostRequest('insertChat' , data).then(res=>{return res.data})
}

export const getHostRequestAction =(data)=>{
  return OwnerGetRequest('getHostRequest' , data).then(res=>{return res.data})
}
export const getPropertyDetailByIdAction =(data)=>{
  return OwnerPostRequest('getPropertyDetailById' , data).then(res=>{return res.data})
}

export const removeImageAction =(data)=>{
  return OwnerPostRequest('removeImage' , data).then(res=>{return res.data})
}

export const getPropertyStandoutemenitiesByIdAction =(data)=>{
  return OwnerPostRequest('getPropertyStandoutemenitiesById' , data).then(res=>{return res.data})
}
export const editGeneralInfoOfPropertyListingAction =(data)=>{
  return OwnerPostRequest('editGeneralInfoOfPropertyListing' , data).then(res=>{return res.data})
}
export const editPropertyListingLocationAction =(data)=>{
  return OwnerPostRequest('editPropertyListingLocation' , data).then(res=>{return res.data})
}
export const setCheckInAndCheckOutTimeAction =(data)=>{
  return OwnerPostRequest('setCheckInAndCheckOutTime' , data).then(res=>{return res.data})
}
export const editPricingOfPropertyListingAction =(data)=>{
  return OwnerPostRequest('editPricingOfPropertyListing' , data).then(res=>{return res.data})
}
export const editPropertyAndRoomsOfListingAction =(data)=>{
  return OwnerPostRequest('editPropertyAndRoomsOfListing' , data).then(res=>{return res.data})
}
export const editAmenitiesOfPropertyListingAction =(data)=>{
  return OwnerPostRequest('editAmenitiesOfPropertyListing' , data).then(res=>{return res.data})
}
export const editEnvironmentOfListingPropertyAction =(data)=>{
  return OwnerPostRequest('editEnvironmentOfListingProperty' , data).then(res=>{return res.data})
}
export const editeditRoomAndSpacesPropertyAction =(data)=>{
  return OwnerPostRequest('editRoomAndSpacesProperty' , data).then(res=>{return res.data})
}
export const updateCancellationPolicyOfPropertyAction =(data)=>{
  return OwnerPostRequest('updateCancellationPolicyOfProperty' , data).then(res=>{return res.data})
}
export const updateHouseRulesOfPropertyAction =(data)=>{
  return OwnerPostRequest('updateHouseRulesOfProperty' , data).then(res=>{return res.data})
}
export const updateCancellationPolicyAction =(data)=>{
  return OwnerPostRequest('updateCancellationPolicy' , data).then(res=>{return res.data})
}
export const updatePetsDataAction =(data)=>{
  return OwnerPostRequest('updatePetsData' , data).then(res=>{return res.data})
}
export const updatePhotoAndVideoPropertyAction =(data)=>{
  return propertyOwnerRequestForm('updatePhotoAndVideoProperty' , data).then(res=>{return res.data})
}
export const AddRoomTypeImagesAction =(data)=>{
  return propertyOwnerRequestForm('AddRoomTypeImages' , data).then(res=>{return res.data})
}
export const getSafetyDetailsByIdAction =(data)=>{
  return OwnerPostRequest('getSafetyDetailsById' , data).then(res=>{return res.data})
}
export const getPropertyPurchaseTypeAction =(data)=>{
  return OwnerGetRequest('getPropertyPurchaseType' , data).then(res=>{return res.data})
}

export const getRoomPhotosAction =(data)=>{
  return OwnerPostRequest('getRoomPhotos' , data).then(res=>{return res.data})
}

export const removeRoomsImageAction =(data)=>{
  return OwnerPostRequest('removeRoomsImage' , data).then(res=>{return res.data})
}

export const addPropertyRoomPhotosAction =(data)=>{
  return propertyOwnerRequestForm('addPropertyRoomPhotos' , data).then(res=>{return res.data})
}

export const addRoomPhotosAction =(data)=>{
  return propertyOwnerRequestForm('addRoomPhotos' , data).then(res=>{return res.data})
}

export const ContactFormAction = (data) => {
  return OwnerPostRequest('addContactUs', data).then(res => { return res.data })
}
export const userAccountDeletionAction = (data) => {
  return OwnerPostRequest('userAccountDeletion', data).then(res => { return res.data })
}

export const getRoomsTypeAction = (data) => {
  return OwnerGetRequest('getRoomType', data).then(res => { return res.data })
}

export const getRefundHistoryAction = (data) => {
  return OwnerPostRequest('getRefundHistory', data).then(res => { return res.data })
}

export const getEmployeeListAction = (data) => {
  return OwnerGetRequest('getEmployeeList', data).then(res => { return res.data })
}

// ---------------------------|| NEW PHASE ||-------------------------------


export const addStaff = (data) => {
  return OwnerPostRequest('addStaff', data).then(res => { return res.data })
}

export const updateStaff = (data) => {
  return OwnerPostRequest('updateStaff', data).then(res => { return res.data })
}

export const updateStaffPassword = (data) => {
  return OwnerPostRequest('updateStaffPassword', data).then(res => { return res.data })
}

export const getStaffList = (data) => {
  return OwnerGetRequest('getStaffList', data).then(res => { return res.data })
}

// EMPLOYEE

export const addEmployeeAPI = (data) => {
  return OwnerPostRequest('addEmployee', data).then(res => { return res.data })
}

export const updateEmployeeAction = (data) => {
  return OwnerPostRequest('updateEmployee', data).then(res => { return res.data })
}

// SUPPLIER 

export const addSupplier = (data) => {
  return OwnerPostRequest('addSupplier', data).then(res => { return res.data })
}

export const updatePaymentStatus = (data) => {
  return OwnerPostRequest('updatePaymentStatus', data).then(res => { return res.data })
}

export const updateSupplier = (data) => {
  return OwnerPostRequest('updateSupplier', data).then(res => { return res.data })
}

export const getSupplierList = (data) => {
  return OwnerGetRequest('getSupplierList', data).then(res => { return res.data })
}

export const getSupplierStatics = (data) => {
  return OwnerGetRequest('getSupplierStatics', data).then(res => { return res.data })
}


// EXPENSE 

export const addExpense = (data) => {
  return OwnerPostRequest('addExpense', data).then(res => { return res.data })
}

export const updateExpense = (data) => {
  return OwnerPostRequest('updateExpense', data).then(res => { return res.data })
}

export const getExpenseList = (data) => {
  return OwnerPostRequest('getExpenseList', data).then(res => { return res.data })
}

export const getPropertyList = (data) => {
  return OwnerGetRequest('getPropertyList', data).then(res => { return res.data })
}

export const getActiveSupplierList = (data) => {
  return OwnerPostRequest('getActiveSupplierList', data).then(res => { return res.data })
}

export const getReceivableReportAction = (data) => {
  return OwnerPostRequest('getReceivableReport', data).then(res => { return res.data })
}