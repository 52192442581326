import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../directives/Header"
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import ListMenuButton from "./listMenu";
import { getReceivableReportAction } from '../../../../Action/propertyOwner.action'

const customStyles = {
    rows: {
        style: {
            minHeight: '70px', // override the row height
        },
    },
    headCells: {
        style: {
            minHeight: '60px',
            paddingLeft: '20px', // override the cell padding for head cells
            paddingRight: '20px',
            background: '#FFF8F4',
        },
    },
    cells: {
        style: {
            paddingLeft: '20px', // override the cell padding for data cells
            paddingRight: '20px',
        },
    },
};

const Receivable = () => {
    const [receivalbeReportList, setReceivalbeReportList] = useState([]);
    useEffect(() => {
        document.body.classList.add('staffbody');
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);

    useEffect(() => {
        getReceivableReportAPI();
    }, []);

    const getReceivableReportAPI = async () => {
        let res = await getReceivableReportAction({type : 1});
        if (res.success) {
            setReceivalbeReportList(res.data || []);
        }
    }

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Property Name',
            selector: row => row.propertyId,
        },
        {
            name: 'Current',
            selector: row => `$${row.under30Days}`,
        },
        {
            name: '01-30 Days',
            selector: row => `$${row.under30Days}`,
            sortable: true,
        },
        {
            name: '31-60 Days',
            selector: row => `$${row.under60Days}`,
            sortable: true,
        },
        {
            name: '60-90 Days',
            selector: row => `$${row.under90Days}`,
            sortable: true,
        },   
        {
            name: '91 & Over Days',
            selector: row => `$${row.above90Days}`,
            sortable: true,
        },                        
    ];

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>

                    <Row className="">
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <div className="d-flex profileMobile align-items-center">
                                <img
                                    src="images/p1.png"
                                    width="40px"
                                    height="40px"
                                    style={{ objectFit: "cover", borderRadius: "50%" }}
                                    className="me-2"
                                />

                                &nbsp;{" "}
                                <div className="profileContent d-flex align-items-center">
                                    <div className="">
                                        <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p>
                                        <div>
                                            <h5 className="mb-0 me-3 fw-bold">Hey Mark Smith</h5>
                                        </div>
                                    </div>

                                    <img src="images/icon/hand.svg" width={`37px`} />
                                </div>
                            </div>

                        </Col>
                        <Col lg={7} sm={12} xs={12} className=" text-right">
                            <Link to={`${config.baseurl}servicedashboard`}>
                                <Button
                                    variant="outline-primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Service Dashboard
                                </Button>
                            </Link>
                            <Link to={`${config.baseurl}vehicledashboard`}>
                                <Button
                                    variant="primary"
                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                >
                                    Vehicle Dashboard
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0">
                                    <div className="d-sm-flex justify-content-between align-items-center">
                                        <div className="mb-4"><h4 className="mb-0 fw-bolder">A/R Aging Summary Report </h4></div>
                                        <div className="">
                                            <Link to={`#`}>
                                                <Button
                                                    variant="outline-primary"
                                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                                >
                                                    Save Customization
                                                </Button>
                                            </Link>
                                            <Link to={`#`}>
                                                <Button
                                                    variant="primary"
                                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                                >
                                                    Customize
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        <Row>
                                            <Col lg={12}>
                                                <Form.Group
                                                    className="me-2"
                                                    controlId="formBasicPassword"
                                                >
                                                    <label className="text-light-grey">Report Period</label>
                                                    <Row className="mt-2">
                                                        <Col lg={3} className="mb-4">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="This year to date"
                                                                name="year"
                                                            />
                                                        </Col>
                                                        <Col lg={3} className="mb-4">
                                                            <div className="position-relative">
                                                                <Form.Control
                                                                    type="date"
                                                                    placeholder="Date"
                                                                    aria-label="firstName"
                                                                    name="Date"
                                                                />
                                                                <span className="text-light-grey inner-field small">From</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} className="mb-4">
                                                            <div className="position-relative">
                                                                <Form.Control
                                                                    type="date"
                                                                    placeholder="Date"
                                                                    aria-label="firstName"
                                                                    name="Date"
                                                                />
                                                                <span className="text-light-grey inner-field small">To</span>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>

                                                        </Col>
                                                        <Col lg={12}>
                                                            <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Display Column By</Form.Label>
                                                                <Form.Control type="text" placeholder="" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Show Non Zero or Active Only</Form.Label>
                                                                <Form.Control type="text" placeholder="" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Compare Another Period </Form.Label>
                                                                <Form.Control type="text" placeholder="" />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={6} className="mb-4">
                                                            <Form.Group className="" controlId="formBasicEmail">
                                                                <Form.Label className="text-light-grey mb-1">Accounting Method</Form.Label>
                                                                <Row className="mt-2">
                                                                    <Col lg={6}>
                                                                        <Form>
                                                                            {['radio'].map((type) => (
                                                                                <div key={`inline-${type}`} className="mb-3">
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Cash"
                                                                                        name="group1"
                                                                                        type={type}
                                                                                        id={`inline-${type}-1`}
                                                                                    />
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Accrual"
                                                                                        name="group1"
                                                                                        type={type}
                                                                                        id={`inline-${type}-2`}
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </Form>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Button
                                                                            variant="primary"
                                                                            className=" rounded-5 me-2 px-4 px-xs-1 w-100 "
                                                                        >
                                                                            Run Report
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="tableCard">
                                        <Card.Body className="p-0">
                                            <div className="">
                                                {receivalbeReportList.length > 0 ?
                                                    <Row className="">
                                                        <div className="tableHeader border-0 px-sm-4 bg-light-grey pb-sm-0">
                                                            <div className="tableBody p-0" >
                                                                <div className="text-center pt-5">
                                                                    <h4 className="mb-1">Receivable Report</h4>
                                                                    <div className="mb-1 fs-5">Statement of receivable flow</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Col lg={12} xs={12}>
                                                            <div className="mb-4">
                                                                <DataTable
                                                                    columns={columns}
                                                                    data={receivalbeReportList}
                                                                    customStyles={customStyles}
                                                                    theme="solarized"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <div className="text-center">
                                                        <div>
                                                            <img src="images/nodatafound.svg" />
                                                        </div>
                                                        <h5 className="mb-1">Receivable Report</h5>
                                                        <div>This report doesn’t have any data</div>
                                                    </div>
                                                }
                                            </div>
                                        </Card.Body>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                </Container>
            </section>
        </div >
    );
};

export default Receivable;
