import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { size } from 'lodash';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const RevenueChart = () => {
	const options = {
    backgroundColor: "transparent", // Sets the background color to transparent
		axisY: {
			valueFormatString: "$#0", // Formats y-axis values with a dollar sign
			labelFontColor: "#6E6E6E",   // Changes y-axis label font color
			labelFontSize: 16,        // Changes y-axis label font size
			gridColor: "#6E6E6E"      // Changes grid line color
		},
		axisX: {
			labelFontColor: "#6E6E6E",   // Changes x-axis label font color
			labelFontSize: 16,
      lineThickness: 0         // Changes x-axis label font size
		},
		data: [
			{
				type: "column",
				toolTipContent: "{label}: ${y}", // Formats tooltip content with a dollar sign
				dataPoints: [
					{ label: "Jan", y: 10 },
					{ label: "Feb", y: 15 },
					{ label: "Mar", y: 25 },
					{ label: "Apr", y: 30 },
					{ label: "May", y: 35 },
          { label: "Jun", y: 30 },
          { label: "Jul", y: 25 },
          { label: "Aug", y: 15 },
          { label: "Sep", y: 10 },
          { label: "Oct", y: 35 },
          { label: "Nov", y: 30 },
          { label: "Dec", y: 25 },
				],
				color: "#FFD8C1", // Changes the color of the columns
        size:"20"
        
			}
		],

	};

	return (
		<div className=''>
			<CanvasJSChart options={options}  containerProps={{ width: '100%', height: '300px' }} />
		</div>
	);
}

export default RevenueChart;
