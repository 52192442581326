import React from 'react';
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaRegStar,FaStar } from "react-icons/fa";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import '../css/testimonial.css'

// import required modules
import { Pagination } from 'swiper/modules';

const Testimonial = () => {


    return (
        <div className=''>
            <Swiper
                slidesPerView={'4'}
                spaceBetween={20}
                loop={`true`}
                modules={[]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>
                <SwiperSlide>
                    <Card className='w-full'>
                        <Card.Body>
                            <div>
                                <h5 className='small mb-1 fst-italic'>City Central Hotel</h5>
                                <p className='x-small mb-3 text-black'>Charming lodge with breathtaking mountain views. Cozy rooms, welcoming staff, and hearty breakfast. Ideal for nature lovers.</p>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <img src="images/t1.svg" className='object-fit-contain' />
                                    </div>
                                    <div>
                                        <h6 className='mb-0 small'>Mark Smith</h6>
                                        <div className='text-primary fw-bold xx-small'>Feb 24, 2024</div>
                                    </div>
                                </div>
                                <div>
                                    <FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaStar className='me-2' fill='#FFC107' /><FaRegStar fill='#FFC107' />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </SwiperSlide>

            </Swiper>
        </div>
    );
}

export default Testimonial;
