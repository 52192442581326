import React, { useState, useEffect } from "react";
import {
  getPropertyAllDetaillistAction,
  getVehicleAllDetailListAction,
  getVehicleTypeAction,
  getvehicleFeaturesAction,
  getvehicleMoreAction,
  addWishlistAction,
  removeFromWishlistAction,
  getPropertyAmenitiesDetailAction,
  getWishListAction,
  getPropertyTypeDetailAction,
  getHomePageDetailsAction,
} from "../../Action/user.action";
import "../../generalPages/generalCSS/home.css";
import "../commanComponents/componentCSS/propertylist.css";
import "../commanComponents/componentCSS/transaction.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../generalPages/directives/header";
import moment from "moment";
import LazyLoad from "react-lazy-load";
import Footer from "../../generalPages/directives/footer";
import config from "../../coreFIles/config";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  Form,
  Dropdown,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IoClose } from "react-icons/io5";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import "../commanComponents/componentCSS/listingproperty.css";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../commanComponents/componentCSS/swiper.css";
import { Pagination } from "swiper/modules";
import MultiRangeSlider from "./multiRangeSlider";
import _debounce from "lodash/debounce";
import { useRef } from "react";

const loginData = !Cookies.get("loginSuccessPropertyOwner")
  ? []
  : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

let priceFilterMinMaxArr = [];
let priceFilterMinMaxArrVehicle = [];

const Propertylist = () => {
  const navigate = useNavigate();
  const {
    location,
    numberOfAdults,
    checkin,
    checkout,
    numberOfChildren,
    numberOfInfants,
    numberOfPets,
    propertyPurchaseTypeId,
  } = useParams();

  const sortByCatProperty = useRef(0);
  const searchByPropertyName = useRef("");
  const searchByPriceMin = useRef("");
  const searchByPriceMax = useRef("");
  const searchByPriceCheckboxMin = useRef("");
  const searchByPriceCheckboxMax = useRef("");
  const filterByRating = useRef(0);
  const filterByBedrooms = useRef("");
  const propertyTypeCheck = useRef([]);
  const propertyAmenityIds = useRef([]);
  const priceFilterArr = useRef([]);

  const [listing, setlisting] = useState([]);
  const [searchListing, setsearchListing] = useState([]);
  const [allsearchList, setallsearchList] = useState([]);
  const [propertyType, setpropertyType] = useState([]);
  const [aminities, setaminities] = useState([]);
  const [noofbadroom, setnoofbadroom] = useState(0);
  const [noofrating, setnoofrating] = useState(0);
  const [searchHotelData, setsearchHotelData] = useState([]);
  const [searchHoteltext, setsearchHoteltext] = useState("");
  const [searchDestinationData, setsearchDestinationData] = useState([]);
  const [AllDestinationData, setAllDestinationData] = useState([]);
  const [searchDesttext, setsearchDesttext] = useState(location);
  const [checkInDate, setCheckInDate] = useState(
    checkin ? checkin : new DateObject()
  );
  const [checkOutDate, setCheckOutDate] = useState(
    checkout ? checkout : new DateObject()
  );
  const [wishList, setwishList] = useState([]);
  const [searchDestEnable, setsearchDestEnable] = useState(false);
  const [searchproEnable, setsearchproEnable] = useState(true);
  const [minprice, setMinprice] = useState(0);
  const [maxprice, setMaxprice] = useState("");
  const [homepage, sethomepage] = useState([]);
  const [priceMinValue, setPriceMinValue] = useState();
  const [priceMaxValue, setPriceMaxValue] = useState();
  const [guestsCount, setguestCount] = useState({
    adults: numberOfAdults ? numberOfAdults : 0,
    children: numberOfChildren ? numberOfChildren : 0,
    infants: numberOfInfants ? numberOfInfants : 0,
    pets: numberOfPets ? numberOfPets : 0,
  });

  useEffect(() => {
    if (propertyPurchaseTypeId == 4) {
      getVehicleList();
      getVehicleTypeslist();
      getVehcileFeatureslist();
      getVehiclecategorieslist();
      getHomePage();
    } else {

      getListingDetails();
      getPropertyTypesdetail();
      getPropertyAminities();
      getHomePage();
    }
    getwishList();
    return () => { };
  }, []);

  const getPropertyAminities = async () => {
    let res = await getPropertyAmenitiesDetailAction();
    if (res.success) {
      setaminities(res.data);
    }
  };

  const getHomePage = async () => {
    let res = await getHomePageDetailsAction();
    if (res.success) {
      sethomepage(res.data[0]);
    }
  };
  const getPropertyTypesdetail = async () => {
    let res = await getPropertyTypeDetailAction();
    if (res.success) {
      setpropertyType(res.data);
    }
  };

  const getListingDetails = async () => {
    let res = await getPropertyAllDetaillistAction({
      propertyPurchaseTypeId: propertyPurchaseTypeId,
      checkInDate: checkin,
      checkOutDate: checkout,
      location: location == "Search" ? "" : location,
      sortByCatProperty: sortByCatProperty.current,
      searchByPropertyName: searchByPropertyName.current,
      searchByPriceMin: searchByPriceMin.current,
      searchByPriceMax: searchByPriceMax.current,
      searchByPriceCheckboxMin: searchByPriceCheckboxMin.current,
      searchByPriceCheckboxMax: searchByPriceCheckboxMax.current,
      priceFilterArr : priceFilterArr.current,
      filterByRating: filterByRating.current,
      filterByBedrooms: filterByBedrooms.current,
      propertyTypeIds: propertyTypeCheck.current,
      propertyAmenityIds: propertyAmenityIds.current,
      totalAdults: guestsCount.adults,
    });
    if (res.success) {
      let filterData = res.data;
      setlisting(filterData);
      setallsearchList(filterData);
      locationDataset(filterData);
    } else {
      setlisting([]);
    }
  };

  // Property Filter apply here ...............

  const setClearFilter = async () => {
    setsearchHoteltext("");
    window.location.reload();
  };

  const priceFilterInput = async (e) => {
    setPriceMinValue(e.min);
    setPriceMaxValue(e.max);
  };

  const applyPriceRangeFilter = async () => {
    searchByPriceMin.current = priceMinValue;
    searchByPriceMax.current = priceMaxValue;
    getListingDetails();
  };

  const selectPricerangeCheckBox = (e, min, max) => {
    if (e.target.checked) {
      priceFilterArr.current.push({'min' : min, 'max' : max})
      priceFilterMinMaxArr.push(parseInt(min));
      priceFilterMinMaxArr.push(parseInt(max));
    } else {
      priceFilterMinMaxArr = priceFilterMinMaxArr.filter((i) => {
        return i != min;
      });
      priceFilterMinMaxArr = priceFilterMinMaxArr.filter((i) => {
        return i != max;
      });
    }
    let minValue = Math.min(...priceFilterMinMaxArr);
    let maxValue = Math.max(...priceFilterMinMaxArr);
    searchByPriceCheckboxMin.current = minValue == "Infinity" ? 0 : minValue;
    searchByPriceCheckboxMax.current = maxValue == "-Infinity" ? 0 : maxValue;
    getListingDetails();
  };

  const locationDataset = (data) => {
    let filter = data;

    const minPriceItem = filter.reduce(
      (min, current) => (min.price < current.price ? min : current),
      filter[0]
    );
    const maxPriceItem = filter.reduce(
      (max, current) => (max.price > current.price ? max : current),
      filter[0]
    );

    setMaxprice(maxPriceItem.price);
    setMinprice(minPriceItem.price);
    setsearchListing(filter);
  };

  const getwishList = async () => {
    if (loginData?.id) {
      let res = await getWishListAction({});
      if (res.success) {
        setwishList(res.data.getWishList);
      }
    }
  };

  const selectPropertyTypeFilter = (e) => {
    window.scrollTo(500, 500);
    if (e.target.checked) {
      propertyTypeCheck.current.push(parseInt(e.target.value));
    } else {
      propertyTypeCheck.current = propertyTypeCheck.current.filter((i) => {
        return i != e.target.value;
      });
    }
    getListingDetails();
  };

  const selectAminitiesFilter = (e) => {
    window.scrollTo(500, 500);
    if (e.target.checked) {
      propertyAmenityIds.current.push(parseInt(e.target.value));
    } else {
      propertyAmenityIds.current = propertyAmenityIds.current.filter((i) => {
        return i != e.target.value;
      });
    }
    getListingDetails();
  };

  const selectBedroomsFilter = (e, value) => {
    window.scrollTo(500, 500);
    if (value > 0 && e.target.checked) {
      filterByBedrooms.current = value;
      setnoofbadroom(value);
      getListingDetails();
    } else {
      filterByBedrooms.current = "";
      setnoofbadroom(0);
      getListingDetails();
    }
  };

  const selectRatingsFilter = (e, value) => {
    if (value > 0 && e.target.checked) {
      setnoofrating(value);
      filterByRating.current = value;
      getListingDetails();
    } else {
      setnoofrating(0);
      filterByRating.current = 0;
      getListingDetails();
    }
  };

  const sortByCategoryFilter = (type) => {
    if (type == 1) {
      sortByCatProperty.current = 1;
    } else {
      sortByCatProperty.current = 2;
    }
    getListingDetails();
  };

  const searchByPropertyNameFilter = (e) => {
    const { name, value } = e.target;
    if (value) {
      setsearchproEnable(true);
      let filter = searchListing.filter((item) =>
        item.propertyName.toUpperCase().match(value.toUpperCase())
      );

      filter.sort((a, b) => a.rating - b.rating);
      filter.sort((a, b) => a.totaluserRating - b.totaluserRating);

      if (value) {
        setsearchHotelData(filter);
      } else {
        searchByPropertyName.current = "";
        getListingDetails();
      }
      setsearchHoteltext(value);
    } else {
      setsearchproEnable(false);
      setsearchHoteltext('');
      setsearchHotelData([]);
    }
  };

  const selectHotel = (e, item) => {
    setsearchproEnable(false);
    setsearchHoteltext(item.propertyName);
    setsearchHotelData([]);
    searchByPropertyName.current = item.propertyName;
    getListingDetails();
  };

  const searchByPropertyDestinationFilter = (e) => {
    const { name, value } = e.target;
    setsearchDestEnable(true);
    let filter = [];
    let arr1 = [];
    if (guestsCount.adults > 0 && value) {
      filter = allsearchList.filter(
        (item) =>
          item.location.toUpperCase().match(value.toUpperCase()) &&
          parseFloat(item.numberOfBedrooms) >=
          parseFloat(guestsCount.adults / 2)
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    } else if (value) {
      filter = allsearchList.filter((item) =>
        item.location.toUpperCase().match(value.toUpperCase())
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    }

    filter = filter.sort(function (a, b) {
      var nameA = a.location.toLowerCase();
      var nameB = b.location.toLowerCase();
      if (nameA === nameB) return 0;
      return nameA > nameB ? 1 : -1;
    });

    arr1 = arr1.sort(function (a, b) {
      var nameA = a.location.toLowerCase();
      var nameB = b.location.toLowerCase();
      if (nameA === nameB) return 0;
      return nameA > nameB ? 1 : -1;
    });

    setsearchDesttext(value);
    setAllDestinationData(filter); 
    setsearchDestinationData(arr1); 
  };

  const selectDestination = (e, value) => {
    let filter = AllDestinationData.filter((item) =>
      item.location.toUpperCase().match(value.toUpperCase())
    );
    filter = filter.sort((a, b) => a.rating - b.rating);
    filter = filter.sort((a, b) => a.totaluserRating - b.totaluserRating);
    setlisting(filter);
    setsearchListing(filter);
    setsearchDestEnable(false);
    setsearchDesttext(value);
    setAllDestinationData([]);
    setsearchDestinationData([]);
  };

  const searchHeader = (e) => {
    getListingDetails();
  };

  const changeCheckout = (e) => {
    setCheckOutDate(moment(e.format()).format("YYYY-MM-DD"));
    navigate(
      `${config.baseUrl}propertylist/${searchDesttext.length > 0 ? searchDesttext : "Search"
      }/${guestsCount.adults}/${moment(checkInDate).format(
        "YYYY-MM-DD"
      )}/${moment(e.format()).format("YYYY-MM-DD")}/${guestsCount.children}/${guestsCount.infants
      }/${guestsCount.pets}/${propertyPurchaseTypeId}`
    );
  };

  const changeCheckIn = (e) => {
    setCheckInDate(moment(e.format()).format("YYYY-MM-DD"));
    navigate(
      `${config.baseUrl}propertylist/${searchDesttext.length > 0 ? searchDesttext : "Search"
      }/${guestsCount.adults}/${moment(e.format()).format(
        "YYYY-MM-DD"
      )}/${moment(checkOutDate).format("YYYY-MM-DD")}/${guestsCount.children}/${guestsCount.infants
      }/${guestsCount.pets}/${propertyPurchaseTypeId}`
    );
  };

  const minusGuestCount = (e, name) => {
    let value =
      parseInt(guestsCount[name]) - 1 < 0 ? 0 : parseInt(guestsCount[name]) - 1;

    setguestCount((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
    navigate(
      `${config.baseUrl}propertylist/${searchDesttext.length > 0 ? searchDesttext : "Search"
      }/${name == "adults" ? value : guestsCount.adults}/${moment(
        checkInDate
      ).format("YYYY-MM-DD")}/${moment(checkOutDate).format("YYYY-MM-DD")}/${name == "children" ? value : guestsCount.children
      }/${name == "infants" ? value : guestsCount.infants}/${name == "pets" ? value : guestsCount.pets
      }/${propertyPurchaseTypeId}`
    );
  };

  const plusGuestCount = (e, name) => {
    let value = parseInt(guestsCount[name]) + 1;
    setguestCount((old) => {
      return {
        ...old,
        [name]: value,
      };
    });
    navigate(
      `${config.baseUrl}propertylist/${searchDesttext.length > 0 ? searchDesttext : "Search"
      }/${name == "adults" ? value : guestsCount.adults}/${moment(
        checkInDate
      ).format("YYYY-MM-DD")}/${moment(checkOutDate).format("YYYY-MM-DD")}/${name == "children" ? value : guestsCount.children
      }/${name == "infants" ? value : guestsCount.infants}/${name == "pets" ? value : guestsCount.pets
      }/${propertyPurchaseTypeId}`
    );
  };

  const redirectToDetailPage = (e, id) => {
    if (checkInDate == checkOutDate) {
      window.open(
        `${config.baseUrl}propertydetail/${id}/${guestsCount.adults}/${moment(
          checkInDate
        ).format("YYYY-MM-DD")}/${moment(checkOutDate)
          .add(1, "days")
          .format("YYYY-MM-DD")}/${guestsCount.children}/${guestsCount.infants
        }/${guestsCount.pets}/${propertyPurchaseTypeId}/0`
      );
    } else {
      window.open(
        `${config.baseUrl}propertydetail/${id}/${guestsCount.adults}/${moment(
          checkInDate
        ).format("YYYY-MM-DD")}/${moment(checkOutDate).format("YYYY-MM-DD")}/${guestsCount.children
        }/${guestsCount.infants}/${guestsCount.pets}/${propertyPurchaseTypeId}/0`
      );
    }
  };

  const addToWishList = async (e, id, type, status) => {
    if (loginData?.id) {
      let res =
        status == 1
          ? await addWishlistAction({ id: id, type: type })
          : await removeFromWishlistAction({ id: id, type: type });
      if (res.success) {
        toast.success(res.msg);
      } else {
        toast.error(res.msg);
      }
      getwishList();
    } else {
      toast.error("Please login !first");
    }
  };

  /*  -------------------------------------Vehicle Module--------------------------------------------  */

  const searchByPriceMinVehicle = useRef("");
  const searchByPriceMaxVehicle = useRef("");
  const searchByPriceCheckboxMinVehicle = useRef("");
  const searchByPriceCheckboxMaxVehicle = useRef("");
  const filterByRatingVehicle = useRef(0);
  const sortByCatVehicle = useRef(0);
  const vehicleTypeIds = useRef([]);
  const vehicleFeaturesIds = useRef([]);
  const vehicleMoreIds = useRef([]);
  const filterBySeats = useRef(0);

  const [vehiclelisting, setvehiclelisting] = useState([]);
  const [vehiclesearchListing, setvehiclesearchListing] = useState([]);
  const [vehicleallsearchList, setvehicleallsearchList] = useState([]);
  const [vehicleminprice, setvehicleminprice] = useState(0);
  const [vehiclemaxprice, setvehiclemaxprice] = useState(0);
  const [progressMinPrice, setprogressMinPrice] = useState();
  const [progressMaxPrice, setprogressMaxPrice] = useState();
  const [vehicleType, setvehicleType] = useState([]);
  const [features, setFeatures] = useState([]);
  const [categorys, setcategorys] = useState([]);
  const [noofseats, setnoofseats] = useState(0);
  const [vehiclenoofrating, setvehiclenoofrating] = useState(0);
  const [searchVehicleData, setsearchVehicleData] = useState([]);
  const [searchVehicletext, setsearchVehicletext] = useState("");
  const [searchvehicleDestinationData, setsearchvehicleDestinationData] =
    useState([]);
  const [AllvehicleDestinationData, setAllvehicleDestinationData] = useState(
    []
  );
  const [searchvehicleDesttext, setsearchvehicleDesttext] = useState("");

  const [values, setValues] = useState([
    new DateObject(),
    new DateObject().add(1, "day"),
  ]);

  const [vehiclecheckInDate, setvehicleCheckInDate] = useState(
    new DateObject()
  );
  const [vehiclecheckOutDate, setvehicleCheckOutDate] = useState(
    new DateObject().add(1, "day")
  );
  const [searchvehicleDestEnable, setsearchvehicleDestEnable] = useState(true);
  const [searchvehicleEnable, setsearchvehicleEnable] = useState(true);

  const getVehicleList = async () => {
    let res = await getVehicleAllDetailListAction({
      checkInDate: checkin,
      checkOutDate: checkout,
      searchByPriceMinVehicle: searchByPriceMinVehicle.current,
      searchByPriceMaxVehicle: searchByPriceMaxVehicle.current,
      searchByPriceCheckboxMinVehicle: searchByPriceCheckboxMinVehicle.current,
      searchByPriceCheckboxMaxVehicle: searchByPriceCheckboxMaxVehicle.current,
      filterByRatingVehicle: filterByRatingVehicle.current,
      sortByCatVehicle: sortByCatVehicle.current,
      vehicleTypeIds: vehicleTypeIds.current,
      vehicleFeaturesIds: vehicleFeaturesIds.current,
      vehicleMoreIds: vehicleMoreIds.current,
      filterBySeats: filterBySeats.current,
    });
    if (res.success) {
      res.data = res.data.filter((item) => item.availableVehicle === 0);
     
      setvehiclelisting(res.data);
      setvehicleallsearchList(res.data);

      setTimeout(() => {
        setvehicleCheckInDate(checkin);
        setvehicleCheckOutDate(checkout);
        setValues([new DateObject(checkin), new DateObject(checkout)]);
        vehiclelocationDataset(location, 0, res.data);
      }, 300);
    } else {
      setvehiclelisting([]);
    }
  };

  const getVehicleTypeslist = async () => {
    let res = await getVehicleTypeAction();
    if (res.success) {
      setvehicleType(res.data);
    }
  };

  const getVehcileFeatureslist = async () => {
    let res = await getvehicleFeaturesAction();
    if (res.success) {
      setFeatures(res.data);
    }
  };

  const getVehiclecategorieslist = async () => {
    let res = await getvehicleMoreAction();
    if (res.success) {
      setcategorys(res.data);
    }
  };

  const getvehicleListingDetailsApply = async () => {
    searchByPriceMinVehicle.current = progressMinPrice;
    searchByPriceMaxVehicle.current = progressMaxPrice;
    getVehicleList();
  };

  useEffect(() => {
    if (values[0].format()) {
      setvehicleCheckInDate(values[0].format());
    }

    if (values.length > 1 && propertyPurchaseTypeId == 4) {
      setvehicleCheckOutDate(values[1].format());
      navigate(
        `${config.baseUrl}propertylist/${searchvehicleDesttext.length > 0 ? searchvehicleDesttext : "Search"
        }/${guestsCount.adults}/${moment(values[0].format()).format(
          "YYYY-MM-DD"
        )}/${moment(values[1].format()).format("YYYY-MM-DD")}/${guestsCount.children
        }/${guestsCount.infants}/${guestsCount.pets}/${propertyPurchaseTypeId}`
      );
    }
  }, [values]);

  const vehicleinputHandler = (e) => {
    setprogressMinPrice(e.min);
    setprogressMaxPrice(e.max);
  };

  const vehiclelocationDataset = (value, numberOfAdults, data) => {
    let filter = [];
    if (location !== "Search") {
      filter = data.filter((item) =>
        item.location.toUpperCase().match(value.toUpperCase())
      );
    } else {
      filter = data;
    }

    const minPriceItem = filter.reduce(
      (min, current) => (min.perHourRate < current.perHourRate ? min : current),
      filter[0]
    );
    const maxPriceItem = filter.reduce(
      (max, current) => (max.perHourRate > current.perHourRate ? max : current),
      filter[0]
    );
    setvehiclemaxprice(maxPriceItem?.perHourRate);
    setvehicleminprice(minPriceItem?.perHourRate);

    setvehiclelisting(filter);
    setvehiclesearchListing(filter);
    setsearchvehicleDestEnable(false);
    setsearchvehicleDesttext(value);
    setAllvehicleDestinationData([]);
    setsearchvehicleDestinationData([]);
  };

  const selectvehiclePricerange = (e, min, max) => {
    if (e.target.checked) {
      priceFilterMinMaxArrVehicle.push(parseInt(min));
      priceFilterMinMaxArrVehicle.push(parseInt(max));
    } else {
      priceFilterMinMaxArrVehicle = priceFilterMinMaxArrVehicle.filter((i) => {
        return i != min;
      });
      priceFilterMinMaxArrVehicle = priceFilterMinMaxArrVehicle.filter((i) => {
        return i != max;
      });
    }
    let minValue = Math.min(...priceFilterMinMaxArrVehicle);
    let maxValue = Math.max(...priceFilterMinMaxArrVehicle);

    searchByPriceCheckboxMinVehicle.current =
      minValue == "Infinity" ? 0 : minValue;
    searchByPriceCheckboxMaxVehicle.current =
      maxValue == "-Infinity" ? 0 : maxValue;

    getVehicleList();
  };

  const selectVehicleType = (e) => {
    window.scrollTo(500, 500);
    if (e.target.checked) {
      vehicleTypeIds.current.push(parseInt(e.target.value));
    } else {
      vehicleTypeIds.current = vehicleTypeIds.current.filter((i) => {
        return i != e.target.value;
      });
    }
    getVehicleList();
  };

  const selectFeatures = (e) => {
    window.scrollTo(500, 500);
    if (e.target.checked) {
      vehicleFeaturesIds.current.push(parseInt(e.target.value));
    } else {
      vehicleFeaturesIds.current = vehicleFeaturesIds.current.filter((i) => {
        return i != e.target.value;
      });
    }
    getVehicleList();
  };

  const selectCategory = (e) => {
    window.scrollTo(500, 500);
    if (e.target.checked) {
      vehicleMoreIds.current.push(parseInt(e.target.value));
    } else {
      vehicleMoreIds.current = vehicleMoreIds.current.filter((i) => {
        return i != e.target.value;
      });
    }
    getVehicleList();
  };

  const selectSeats = (e, value) => {
    window.scrollTo(500, 500);
    if (value > 0 && e.target.checked) {
      filterBySeats.current = value;
      setnoofseats(value);
      getVehicleList();
    } else {
      filterBySeats.current = "";
      setnoofseats(0);
      getVehicleList();
    }
  };

  const selectvehicleRatings = (e, value) => {
    if (value > 0 && e.target.checked) {
      setvehiclenoofrating(value);
      filterByRatingVehicle.current = value;
      getVehicleList();
    } else {
      setvehiclenoofrating(0);
      filterByRatingVehicle.current = 0;
      getVehicleList();
    }
  };

  const sortvehicleCategory = (e, type) => {
    if (type == 1) {
      sortByCatVehicle.current = 1;
    } else {
      sortByCatVehicle.current = 2;
    }
    getVehicleList();
  };

  const searchvehicleHandler = (e) => {
    const { name, value } = e.target;
    setsearchproEnable(true);
    let filter = vehiclesearchListing.filter((item) =>
      item.name.toUpperCase().match(value.toUpperCase())
    );

    if (value) {
      setsearchVehicleData(filter);
      setsearchVehicletext(value);
    } else {
      getVehicleList();
      setsearchVehicletext("");
      setsearchVehicleData([]);
    }
  };

  const selectVehicle = (e, item) => {
    setsearchvehicleEnable(false);
    setsearchVehicletext(item.name);
    setvehiclelisting([item]);
    setsearchVehicleData([]);
  };

  const searchvehicleDestHandler = (e) => {
    const { name, value } = e.target;
    setsearchvehicleDestEnable(true);
    let filter = [];
    let arr1 = [];
    if (value) {
      filter = vehicleallsearchList.filter((item) =>
        item.location.toUpperCase().match(value.toUpperCase())
      );
      arr1 = filter.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.location.toUpperCase() === v.location.toUpperCase()
          ) === i
      );
    }
    setsearchvehicleDesttext(value);
    setAllvehicleDestinationData(filter); //set  duplicate locations data which is related to match
    setsearchvehicleDestinationData(arr1); //set only unic location data remove duplicate locations data
  };

  const selectvehicleDestination = (e, value) => {
    let filter = AllvehicleDestinationData.filter((item) =>
      item.location.toUpperCase().match(value.toUpperCase())
    );
    filter = filter.sort((a, b) => a.rating - b.rating);
    filter = filter.sort((a, b) => a.totaluserRating - b.totaluserRating);
    setvehiclelisting(filter);
    setvehiclesearchListing(filter);
    setsearchvehicleDestEnable(false);
    setsearchvehicleDesttext(value);
    setAllvehicleDestinationData([]);
    setsearchvehicleDestinationData([]);
  };

  const gotovehicleDetail = (e, id) => {
    window.open(
      `${config.baseUrl}vehicledetail/${id}/${propertyPurchaseTypeId}/${moment(
        vehiclecheckInDate
      ).format("YYYY-MM-DD HH:mm")}/${moment(vehiclecheckOutDate).format(
        "YYYY-MM-DD HH:mm"
      )}`
    );
  };

  const clearAllFilters = async () => {
    window.location.reload();
  };

  return (
    <>
      <Header />
      <Toaster></Toaster>
      <section
        className="banner-area banner-area-two banner-bg bannerHome d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: `url(${homepage && homepage.image
              ? homepage.image
              : "assets/images/banner/banner-bg.webp"
            })`,
        }}
      >
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col xl={9} lg={11} md={12} >
              <div className="text-center">
                <h1 className="text-white">
                  {homepage?.description !== null ||
                    homepage?.description !== " " ||
                    homepage?.description !== undefined ? (
                    <b
                      dangerouslySetInnerHTML={{
                        __html: homepage?.description,
                      }}
                    ></b>
                  ) : (
                    <b>
                      Unlock the door to <br /> unforgettable experiences.
                    </b>
                  )}
                </h1>
              </div>
              <div className="banner-location bg-white rounded-5 p-2 mt-5 position-relative pr-0">
                <Container>
                  <Row>
                    {propertyPurchaseTypeId == 4 ? (
                      <Col lg={3} md={3} xs={12} className="">
                        <InputGroup className="bannerInput searchbtn">
                          <Button
                            variant="default"
                            id="button-addon1"
                            className="border-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                fill="black"
                              />
                            </svg>
                          </Button>
                          <Form.Control
                          style={{borderRight:"1px solid #838383"}}
                            aria-label="Example text with button addon"
                            onChange={(e) => searchvehicleDestHandler(e)}
                            value={searchvehicleDesttext}
                            aria-describedby="basic-addon1"
                            placeholder="Destination"
                          />

                          {searchvehicleDesttext !== "Search" &&
                            searchvehicleDesttext.length > 0 ? (
                            <Button
                              variant="default"
                              id="button-addon1"
                              className="border-0"
                              onClick={(e) => setsearchvehicleDesttext("")}
                            >
                              <IoClose fill="#838383" />
                            </Button>
                          ) : (
                            ""
                          )}
                        </InputGroup>

                        {searchvehicleDestinationData.length > 0 ? (
                          <div className="search_destination rounded-5">
                            <div className="list h-auto">
                              <ul>
                                {searchvehicleDestinationData.map((item) => {
                                  return (
                                    <>
                                      <li
                                        onClick={(e) =>
                                          selectvehicleDestination(
                                            e,
                                            item.location
                                          )
                                        }
                                        className="border-0"
                                      >
                                        <div>
                                          <h6 className="mb-0">
                                            {/* <b>{item.propertyName}</b> */}
                                          </h6>
                                          <p className="mb-0 text-grey">
                                            {item.location}
                                          </p>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </div>{" "}
                          </div>
                        ) : searchvehicleDestinationData.length == 0 &&
                          searchvehicleDesttext.length > 0 &&
                          searchvehicleDestEnable == true ? (
                          <div className="search_destination rounded-5">
                            <div className="list h-auto text-center">
                              <ul>
                                <li className="border-0">
                                  <div>
                                    <h6 className="mb-0">
                                      {/* <b>{item.propertyName}</b> */}
                                    </h6>
                                    <p className="mb-0 text-grey">
                                      No Data Found
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    ) : propertyPurchaseTypeId == 1 ||
                      propertyPurchaseTypeId == 3 ? (
                      <Col lg={10} md={10} xs={12} className="">
                        <InputGroup className="bannerInput searchbtn">
                          {/* {searchIcon === true ? '' :  */}

                          <Button
                            variant="default"
                            id="button-addon1"
                            className="border-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                fill="black"
                              />
                            </svg>
                          </Button>
                          {/* // } */}

                          <Form.Control
                          style={{borderRight:"1px solid #838383"}}
                            aria-label="Example text with button addon"
                            onChange={(e) =>
                              searchByPropertyDestinationFilter(e)
                            }
                            value={
                              searchDesttext === "Search" ? "" : searchDesttext
                            }
                            aria-describedby="basic-addon1"
                            placeholder="Destination"
                            className="border-0"
                          />
                          {searchDesttext !== "Search" &&
                            searchDesttext.length > 0 ? (
                            <Button
                              variant="default"
                              id="button-addon1"
                              className="border-0"
                              onClick={(e) => setsearchDesttext("")}
                            >
                              <IoClose fill="#838383" />
                            </Button>
                          ) : (
                            ""
                          )}
                        </InputGroup>

                        {searchDestinationData.length > 0 ? (
                          <div className="search_destination rounded-5">
                            <div className="list h-auto">
                              <ul>
                                {searchDestinationData.map((item) => {
                                  return (
                                    <>
                                      <li
                                        onClick={(e) =>
                                          selectDestination(e, item.location)
                                        }
                                        className="border-0"
                                      >
                                        <div>
                                          <h6 className="mb-0">
                                            {/* <b>{item.propertyName}</b> */}
                                          </h6>
                                          <p className="mb-0 text-grey">
                                            {item.location}
                                          </p>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </div>{" "}
                          </div>
                        ) : searchDestinationData.length == 0 &&
                          searchDesttext.length > 0 &&
                          searchDestEnable == true ? (
                          <div className="search_destination rounded-5">
                            <div className="list h-auto text-center">
                              <ul>
                                <li className="border-0">
                                  <div>
                                    <h6 className="mb-0">
                                      {/* <b>{item.propertyName}</b> */}
                                    </h6>
                                    <p className="mb-0 text-grey">
                                      No Data Found
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    ) : (
                      <Col lg={3} md={3} xs={12} className="">
                        <InputGroup className="bannerInput searchbtn">
                          {/* {searchIcon === true ? '' :  */}

                          <Button
                            variant="default"
                            id="button-addon1"
                            className="border-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.06176 7.66933C5.48796 4.24469 8.82961 2.01004 12.5393 2.00013C16.5375 1.97907 20.0926 4.54006 21.339 8.3391C22.5853 12.1381 21.2381 16.3074 18.0044 18.6588C14.7707 21.0103 10.3893 21.0068 7.15934 18.6501L4.27934 21.5301C3.98652 21.8226 3.51216 21.8226 3.21934 21.5301C2.92689 21.2373 2.92689 20.7629 3.21934 20.4701L6.00934 17.6801C3.40324 15.04 2.63556 11.094 4.06176 7.66933ZM5.39891 14.1691C6.59638 17.0547 9.41508 18.9342 12.5393 18.9301V18.8901C16.7796 18.8847 20.2219 15.4603 20.2493 11.2201C20.2534 8.09586 18.374 5.27717 15.4883 4.0797C12.6026 2.88223 9.27967 3.54208 7.07048 5.75127C4.86129 7.96046 4.20144 11.2834 5.39891 14.1691Z"
                                fill="black"
                              />
                            </svg>
                          </Button>
                          {/* // } */}

                          <Form.Control
                          style={{borderRight:"1px solid #838383"}}
                            aria-label="Example text with button addon"
                            onChange={(e) =>
                              searchByPropertyDestinationFilter(e)
                            }
                            value={
                              searchDesttext === "Search" ? "" : searchDesttext
                            }
                            aria-describedby="basic-addon1"
                            placeholder="Destination"
                          />

                          {searchDesttext !== "Search" &&
                            searchDesttext.length > 0 ? (
                            <Button
                              variant="default"
                              id="button-addon1"
                              className="border-0"
                              onClick={(e) => setsearchDesttext("")}
                            >
                              <IoClose fill="#838383" />
                            </Button>
                          ) : (
                            ""
                          )}
                        </InputGroup>

                        {searchDestinationData.length > 0 ? (
                          <div className="search_destination rounded-5">
                            <div className="list h-auto">
                              <ul>
                                {searchDestinationData.map((item) => {
                                  return (
                                    <>
                                      <li
                                        onClick={(e) =>
                                          selectDestination(e, item.location)
                                        }
                                        className="border-0"
                                      >
                                        <div>
                                          <h6 className="mb-0">
                                            {/* <b>{item.propertyName}</b> */}
                                          </h6>
                                          <p className="mb-0 text-grey">
                                            {item.location}
                                          </p>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </div>{" "}
                          </div>
                        ) : searchDestinationData.length == 0 &&
                          searchDesttext.length > 0 &&
                          searchDestEnable == true ? (
                          <div className="search_destination rounded-5">
                            <div className="list h-auto text-center">
                              <ul>
                                <li className="border-0">
                                  <div>
                                    <h6 className="mb-0">
                                      {/* <b>{item.propertyName}</b> */}
                                    </h6>
                                    <p className="mb-0 text-grey">
                                      No Data Found
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    )}
                    {propertyPurchaseTypeId == 4 ? (
                      <Col lg={9} md={9} xs={12}>
                        <Row>
                          <Col
                            className="input-border-none"
                            lg={8}
                            md={8}
                            xs={6}
                          >
                            <InputGroup className="bannerInput">
                              <Button variant="default" id="button-addon1">
                                <img src="assets/images/icon/calender.svg" />
                              </Button>
                              <DatePicker
                                value={values}
                                minDate={moment().toDate()}
                                onChange={setValues}
                                className="border-0"
                                // className="two_month_date border-0"
                                numberOfMonths={2}
                                range
                              />
                            </InputGroup>
                          </Col>
                          <Col lg={4} md={4} className="">
                            <div className="d-flex justify-content-between ">
                              <div>
                                <Button
                                  onClick={(e) => searchHeader(e)}
                                  variant="primary"
                                  className="rounded-5 position-absolute p-0 btnsearch"
                                  style={{ right: "12px", top: "11px" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 25 25"
                                    fill="none"
                                  >
                                    <circle
                                      cx="10.8333"
                                      cy="10.8333"
                                      r="9.33333"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M17.25 17.7841L23.0833 23.6175"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ) : propertyPurchaseTypeId == 1 ||
                      propertyPurchaseTypeId == 3 ? (
                      <Col lg={2} md={2} xs={12}>
                        <Row>
                          {propertyPurchaseTypeId != 2 ? (
                            <Col lg={12} md={12} className="">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <Button
                                    onClick={(e) => searchHeader(e)}
                                    variant="primary"
                                    className="rounded-5 position-absolute p-0 btnsearch"
                                    style={{ right: "12px", top: "11px" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 25 25"
                                      fill="none"
                                    >
                                      <circle
                                        cx="10.8333"
                                        cy="10.8333"
                                        r="9.33333"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M17.25 17.7841L23.0833 23.6175"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    ) : (
                      <Col lg={9} md={9} xs={12}>
                        <Row>
                          {propertyPurchaseTypeId == 2 ? (
                            <Col lg={4} md={4} xs={6}>
                              <InputGroup className="bannerInput">
                                <Button variant="default" id="button-addon1">
                                  <img src="assets/images/icon/calender.svg" />
                                </Button>
                              </InputGroup>
                              <DatePicker
                                value={checkInDate}
                                minDate={moment().toDate()}
                                onChange={(e) => changeCheckIn(e)}
                                numberOfMonths={2}
                              />
                            </Col>
                          ) : (
                            ""
                          )}
                          {propertyPurchaseTypeId == 2 ? (
                            <Col lg={4} md={4} xs={6}>
                              <InputGroup className="bannerInput">
                                <Button variant="default" id="button-addon1">
                                  <img src="assets/images/icon/calender.svg" />
                                </Button>
                                <DatePicker
                                  placeholder="Check-out"
                                  selected={checkOutDate}
                                  value={checkOutDate}
                                  selectsEnd
                                  startDate={checkInDate}
                                  endDate={checkOutDate}
                                  // minDate={moment().toDate()}
                                  minDate={checkInDate}
                                  onChange={(e) => changeCheckout(e)}
                                  numberOfMonths={2}

                                /**  placeholder="Check-out"
                                          selected={checkOutDate}
                                          value={checkOutDate}
                                          selectsEnd
                                          startDate={checkInDate}
                                          endDate={checkOutDate}
                                          // minDate={moment(checkInDate).add(1, 'days').toDate()}
                                          minDate={checkInDate}
                                          onChange={setCheckOutDate}
                                          numberOfMonths={2} */
                                />
                              </InputGroup>
                            </Col>
                          ) : (
                            ""
                          )}
                          {propertyPurchaseTypeId == 2 ? (
                            <Col lg={4} md={4} className="">
                              <div className="d-flex justify-content-between ">
                                <div className="guest_dropdown">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="default"
                                      id="dropdown-basic"
                                      className="border-0"
                                    >
                                      <img src="assets/images/icon/user.svg" />{" "}
                                      {parseInt(guestsCount.adults) == 0
                                        ? "Add Guests"
                                        : "Guests"}
                                      &nbsp;
                                      {parseInt(guestsCount.adults) > 0
                                        ? parseInt(guestsCount.adults)
                                        : ""}
                                      {parseInt(guestsCount.adults) > 0 &&
                                        parseInt(guestsCount.infants) +
                                        parseInt(guestsCount.children) +
                                        parseInt(guestsCount.pets) >
                                        0
                                        ? " , "
                                        : ""}
                                      {parseInt(guestsCount.infants) +
                                        parseInt(guestsCount.children) +
                                        parseInt(guestsCount.pets) ==
                                        0
                                        ? ""
                                        : "Others"}
                                      &nbsp;
                                      {parseInt(
                                        parseInt(guestsCount.infants) +
                                        parseInt(guestsCount.children) +
                                        parseInt(guestsCount.pets)
                                      ) > 0
                                        ? parseInt(
                                          parseInt(guestsCount.infants) +
                                          parseInt(guestsCount.children) +
                                          parseInt(guestsCount.pets)
                                        )
                                        : ""}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div className="px-2">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>Adults</div>
                                          <div>
                                            <div class="input-group">
                                              <span class="input-group-btn">
                                                <button
                                                  name="adults"
                                                  onClick={(e) =>
                                                    minusGuestCount(e, "adults")
                                                  }
                                                  type="button"
                                                  class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faMinus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                              <input
                                                type="text"
                                                name="guestQuantity"
                                                class="form-control  input-number border-0"
                                                min="1"
                                                max="10"
                                                value={guestsCount.adults}
                                              />
                                              <span class="input-group-btn">
                                                <button
                                                  name="adults"
                                                  onClick={(e) =>
                                                    plusGuestCount(e, "adults")
                                                  }
                                                  type="button"
                                                  class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faPlus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>Children</div>
                                          <div>
                                            <div class="input-group">
                                              <span class="input-group-btn">
                                                <button
                                                  type="button"
                                                  name="children"
                                                  onClick={(e) =>
                                                    minusGuestCount(
                                                      e,
                                                      "children"
                                                    )
                                                  }
                                                  class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                  data-type="plus"
                                                  data-field="quant[1]"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faMinus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                              <input
                                                type="text"
                                                name="guestQuantity"
                                                class="form-control  input-number border-0"
                                                min="1"
                                                max="10"
                                                value={guestsCount.children}
                                              />
                                              <span class="input-group-btn">
                                                <button
                                                  type="button"
                                                  name="children"
                                                  onClick={(e) =>
                                                    plusGuestCount(
                                                      e,
                                                      "children"
                                                    )
                                                  }
                                                  class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                  data-type="minus"
                                                  data-field="quant[1]"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faPlus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>Infants</div>
                                          <div>
                                            <div class="input-group">
                                              <span class="input-group-btn">
                                                <button
                                                  name="infants"
                                                  onClick={(e) =>
                                                    minusGuestCount(
                                                      e,
                                                      "infants"
                                                    )
                                                  }
                                                  type="button"
                                                  class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                  data-type="plus"
                                                  data-field="quant[1]"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faMinus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                              <input
                                                type="text"
                                                name="guestQuantity"
                                                class="form-control  input-number border-0"
                                                min="1"
                                                max="10"
                                                value={guestsCount.infants}
                                              />
                                              <span class="input-group-btn">
                                                <button
                                                  name="infants"
                                                  onClick={(e) =>
                                                    plusGuestCount(e, "infants")
                                                  }
                                                  type="button"
                                                  class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                  data-type="minus"
                                                  data-field="quant[1]"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faPlus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>Pets</div>
                                          <div>
                                            <div class="input-group">
                                              <span class="input-group-btn">
                                                <button
                                                  type="button"
                                                  name="pets"
                                                  onClick={(e) =>
                                                    minusGuestCount(e, "pets")
                                                  }
                                                  class="btn   btn-sm btn-number rounded-5 border border-secondary"
                                                  data-type="plus"
                                                  data-field="quant[1]"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faMinus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                              <input
                                                type="text"
                                                name="guestQuantity"
                                                class="form-control  input-number border-0"
                                                min="1"
                                                max="10"
                                                value={guestsCount.pets}
                                              />
                                              <span class="input-group-btn">
                                                <button
                                                  type="button"
                                                  class="btn  btn-sm btn-number rounded-5 border border-secondary"
                                                  data-type="minus"
                                                  name="pets"
                                                  onClick={(e) =>
                                                    plusGuestCount(e, "pets")
                                                  }
                                                  data-field="quant[1]"
                                                >
                                                  <span class="">
                                                    <FontAwesomeIcon
                                                      icon={faPlus}
                                                    />
                                                  </span>
                                                </button>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div>
                                  <Button
                                    onClick={(e) => searchHeader(e)}
                                    variant="primary"
                                    className="rounded-5 position-absolute p-0 btnsearch"
                                    style={{ right: "12px", top: "11px" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 25 25"
                                      fill="none"
                                    >
                                      <circle
                                        cx="10.8333"
                                        cy="10.8333"
                                        r="9.33333"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M17.25 17.7841L23.0833 23.6175"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {propertyPurchaseTypeId != 2 ? (
                            <Col lg={12} md={12} className="">
                              <div className="d-flex justify-content-between ">
                                <div>
                                  <Button
                                    onClick={(e) => searchHeader(e)}
                                    variant="primary"
                                    className="rounded-5 position-absolute p-0 btnsearch"
                                    style={{ right: "12px", top: "11px" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 25 25"
                                      fill="none"
                                    >
                                      <circle
                                        cx="10.8333"
                                        cy="10.8333"
                                        r="9.33333"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M17.25 17.7841L23.0833 23.6175"
                                        stroke="white"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    )}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="propertylist">
        <Container>
          <Row>
            {
              propertyPurchaseTypeId == 4 ? (
                /*---------------------------------Vehicle Module Start-------------------------------------*/
                <Col lg={4} className="mb-5">
                  <div className="filter-area position-relative">
                    <Card className="p-0">
                      <div className="filter-head">
                        <h6 className="mb-0">
                          <b>Filter By:</b>
                          <span
                            style={{ float: "right", cursor: "pointer" }}
                            onClick={clearAllFilters}
                          >
                            {" "}
                            Clear all{" "}
                          </span>
                        </h6>
                      </div>
                      <div className="searchfield mt-3">
                        <InputGroup className="">
                          <Form.Control
                            aria-label="Example text with button addon"
                            aria-describedby="basic-addon1"
                            placeholder="Search by vehicle name"
                            value={searchVehicletext}
                            onChange={(e) => searchvehicleHandler(e, 1)}
                          />
                          <Button
                            variant="default"
                            id="button-addon1"
                            className="border-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                            >
                              <ellipse
                                cx="8.16667"
                                cy="8.16667"
                                rx="6.66667"
                                ry="6.66667"
                                stroke="#838383"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12.75 13.1318L16.9167 17.2985"
                                stroke="#838383"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Button>
                        </InputGroup>
                        {searchVehicleData.length > 0 ? (
                          <div className="list bg-white shadow-lg rounded-2">
                            <ul>
                              {searchVehicleData.map((item) => {
                                return (
                                  <>
                                    <li
                                      onClick={(e) => selectVehicle(e, item)}
                                      className="border-0"
                                    >
                                      <div>
                                        <h6 className="mb-0">
                                          <b>{item.name}</b>
                                        </h6>
                                        <p className="mb-0 text-grey">
                                          {item.location}
                                        </p>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        ) : searchVehicleData.length == 0 &&
                          searchVehicletext.length > 0 &&
                          searchvehicleEnable == true ? (
                          <div className="list h-auto text-center">
                            <ul>
                              <>
                                <li className="border-0">
                                  <div>
                                    <h6 className="mb-0  text-grey rounded-2">
                                      No data found
                                    </h6>
                                  </div>
                                </li>
                              </>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Price</b>
                        </p>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="rangeslider mt-2 ">
                              {vehicleminprice ? (
                                <MultiRangeSlider
                                  min={
                                    vehicleminprice == ""
                                      ? 0
                                      : parseFloat(vehicleminprice)
                                  }
                                  max={
                                    vehiclemaxprice == ""
                                      ? 0
                                      : parseFloat(vehiclemaxprice)
                                  }
                                  // min={0} max={100}
                                  defaultValue={[
                                    parseFloat(vehicleminprice) || 0,
                                    parseFloat(vehiclemaxprice) || 0,
                                  ]}
                                  onChange={(e) => vehicleinputHandler(e)}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 text-right">
                            <button
                              className="rounded-5 px-4 btn btn-primary"
                              style={{ height: "30px" }}
                              onClick={getvehicleListingDetailsApply}
                            >
                              Apply
                            </button>
                          </div>
                        </div>

                        <br />
                        <div className="listcheckbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Under $10"
                                  name="group1"
                                  onChange={(e) =>
                                    selectvehiclePricerange(e, 0, 10)
                                  }
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  inline
                                  label="$26 - $50"
                                  onChange={(e) =>
                                    selectvehiclePricerange(e, 26, 50)
                                  }
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-2`}
                                />
                                <Form.Check
                                  inline
                                  label="$11 - $25"
                                  onChange={(e) =>
                                    selectvehiclePricerange(e, 11, 25)
                                  }
                                  type={type}
                                  className="mt-2 w-50 mr-0"
                                  id={`inline-${type}-3`}
                                />
                                <Form.Check
                                  inline
                                  label="Above $50"
                                  name="group1"
                                  className="mt-2 w-50 mr-0"
                                  onChange={(e) =>
                                    selectvehiclePricerange(e, 51, 10000000)
                                  }
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Rating</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  label="2 Star"
                                  value={2}
                                  onChange={(e) => selectvehicleRatings(e, 2)}
                                  checked={
                                    vehiclenoofrating == 2 ? true : false
                                  }
                                  name="group1"
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  inline
                                  label="4 Star"
                                  name="group1"
                                  value={4}
                                  onChange={(e) => selectvehicleRatings(e, 4)}
                                  checked={
                                    vehiclenoofrating == 4 ? true : false
                                  }
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-2`}
                                />
                                <Form.Check
                                  inline
                                  label="3 Star"
                                  value={3}
                                  onChange={(e) => selectvehicleRatings(e, 3)}
                                  checked={
                                    vehiclenoofrating == 3 ? true : false
                                  }
                                  type={type}
                                  className="mt-2 w-50 mr-0"
                                  id={`inline-${type}-3`}
                                />
                                <Form.Check
                                  inline
                                  label="5 Star"
                                  name="group1"
                                  value={5}
                                  onChange={(e) => selectvehicleRatings(e, 5)}
                                  checked={
                                    vehiclenoofrating == 5 ? true : false
                                  }
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Vehicle type</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {vehicleType.map((item, value) => (
                              <Form.Check
                                inline
                                label={item.name}
                                name={vehicleTypeIds}
                                value={item.id}
                                onChange={(e) => selectVehicleType(e)}
                                className="mt-2 w-50 mr-0"
                                type="checkbox"
                                id={`inline-checkbox-1`}
                              />
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Features</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {features.map((item, value) => (
                              <Form.Check
                                inline
                                label={item.name}
                                value={item.id}
                                name="vehicleFeaturesIds"
                                onChange={(e) => selectFeatures(e)}
                                className="mt-2 w-50 mr-0"
                                type="checkbox"
                                id={`inline-checkbox-1`}
                              />
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>No. of Seats</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  inline
                                  onChange={(e) => selectSeats(e, 3)}
                                  value={3}
                                  checked={noofseats == 3 ? true : false}
                                  label="3"
                                  name="Three"
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-2`}
                                />
                                <Form.Check
                                  inline
                                  label="1"
                                  onChange={(e) => selectSeats(e, 1)}
                                  checked={noofseats == 1 ? true : false}
                                  value={1}
                                  name="One"
                                  type={type}
                                  className="mt-2 w-50 mr-0"
                                  id={`inline-${type}-3`}
                                />
                                <Form.Check
                                  inline
                                  label="4"
                                  onChange={(e) => selectSeats(e, 4)}
                                  checked={noofseats == 4 ? true : false}
                                  value={4}
                                  name="Four"
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  inline
                                  label="2"
                                  onChange={(e) => selectSeats(e, 2)}
                                  checked={noofseats == 2 ? true : false}
                                  value={2}
                                  name="Two"
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  inline
                                  onChange={(e) => selectSeats(e, 5)}
                                  checked={noofseats == 5 ? true : false}
                                  value={5}
                                  label="5+"
                                  name="Five"
                                  className="mt-2 w-50 mr-0"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Fuel Type</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {categorys.map((item, value) => (
                              <Form.Check
                                inline
                                label={item.name}
                                value={item.id}
                                name="vehicleMoreIds"
                                onChange={(e) => selectCategory(e)}
                                className="mt-2 w-50 mr-0"
                                type="checkbox"
                                id={`inline-checkbox-1`}
                              />
                            ))}
                          </Form>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              ) : (
                <Col lg={4} className="mb-5">
                  <div className="filter-area position-relative">
                    <Card className="p-0">
                      <div className="filter-head">
                        <h6 className="mb-0">
                          <b>Filter By:</b>
                          <span
                            style={{ float: "right", cursor: "pointer" }}
                            onClick={clearAllFilters}
                          >
                            {" "}
                            Clear all{" "}
                          </span>
                        </h6>
                      </div>
                      <div className="searchfield px-4 mt-3">
                        <InputGroup className="">
                          <Form.Control
                            aria-label="Example text with button addon"
                            aria-describedby="basic-addon1"
                            placeholder="Search by property name"
                            value={searchHoteltext}
                            onChange={(e) => searchByPropertyNameFilter(e, 1)}
                          />
                          {searchHoteltext.length > 0 ? (
                            <Button
                              variant="default"
                              id="button-addon1"
                              className="border-0"
                              onClick={(e) => setClearFilter()}
                            >
                              <IoClose fill="#838383" />
                            </Button>
                          ) : (
                            <Button
                              variant="default"
                              id="button-addon1"
                              className="border-0"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                              >
                                <ellipse
                                  cx="8.16667"
                                  cy="8.16667"
                                  rx="6.66667"
                                  ry="6.66667"
                                  stroke="#838383"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12.75 13.1318L16.9167 17.2985"
                                  stroke="#838383"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </Button>
                          )}
                        </InputGroup>
                        {searchHotelData.length > 0 ? (
                          <div className="list bg-white shadow-lg rounded rounded-2">
                            <ul>
                              {searchHotelData.map((item) => {
                                return (
                                  <>
                                    <li
                                      onClick={(e) => selectHotel(e, item)}
                                      className="border-0"
                                    >
                                      <div>
                                        <h6 className="mb-1">
                                          <b>{item.propertyName}</b>
                                        </h6>
                                        <p className="mb-0 text-grey small">
                                          {item.location}
                                        </p>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </div>
                        ) : searchHotelData.length == 0 &&
                          searchHoteltext.length > 0 &&
                          searchproEnable == true ? (
                          <div className="list h-auto text-center bg-white shadow-lg rounded rounded-2 py-4">
                            <ul>
                              <>
                                <li className="border-0 ">
                                  <div>
                                    <h6 className="mb-0  text-grey rounded-2">
                                      No data found
                                    </h6>
                                  </div>
                                </li>
                              </>
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Price</b>
                        </p>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="rangeslider mt-2 ">
                              {minprice ? (
                                <MultiRangeSlider
                                  min={
                                    minprice == "" ? 0 : parseFloat(minprice)
                                  }
                                  max={
                                    maxprice == "" ? 0 : parseFloat(maxprice)
                                  }
                                  defaultValue={[
                                    parseFloat(minprice) || 0,
                                    parseFloat(maxprice) || 0,
                                  ]}
                                  onChange={(e) => priceFilterInput(e)}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 text-right">
                            <button
                              className="btn btn-sm btn-primary"
                              style={{ height: "30px" }}
                              onClick={applyPriceRangeFilter}
                            >
                              Apply
                            </button>
                          </div>
                        </div>

                        {/* <br />
                        <br /> */}

                        <br />
                        <div className="listcheckbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  label="Under $50"
                                  name="group1"
                                  onChange={(e) =>
                                    selectPricerangeCheckBox(e, 0, 50)
                                  }
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  label="$101 - $150"
                                  name="group1"
                                  onChange={(e) =>
                                    selectPricerangeCheckBox(e, 101, 150)
                                  }
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-2`}
                                />
                                <Form.Check
                                  inline
                                  label="$51 - $100"
                                  onChange={(e) =>
                                    selectPricerangeCheckBox(e, 51, 100)
                                  }
                                  type={type}
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  id={`inline-${type}-3`}
                                />
                                <Form.Check
                                  label="Above $ 150"
                                  name="group1"
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  onChange={(e) =>
                                    selectPricerangeCheckBox(
                                      e,
                                      151,
                                      10000000000
                                    )
                                  }
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Rating</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  label="2 Star"
                                  value={2}
                                  onChange={(e) => selectRatingsFilter(e, 2)}
                                  checked={noofrating == 2 ? true : false}
                                  name="group1"
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  label="4 Star"
                                  name="group1"
                                  value={4}
                                  onChange={(e) => selectRatingsFilter(e, 4)}
                                  checked={noofrating == 4 ? true : false}
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-2`}
                                />
                                <Form.Check
                                  label="3 Star"
                                  value={3}
                                  onChange={(e) => selectRatingsFilter(e, 3)}
                                  checked={noofrating == 3 ? true : false}
                                  type={type}
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  id={`inline-${type}-3`}
                                />
                                <Form.Check
                                  label="5 Star"
                                  name="group1"
                                  value={5}
                                  onChange={(e) => selectRatingsFilter(e, 5)}
                                  checked={noofrating == 5 ? true : false}
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Property type</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            <div key={`inline-checkbox`} className="mb-3">
                              {propertyType.map((item, value) => (
                                <Form.Check
                                  // checked={propertyType[item]}
                                  label={[item.name]}
                                  name="propertyTypeIds"
                                  value={item.id}
                                  onChange={(e) => selectPropertyTypeFilter(e)}
                                  className="mt-2 mr-0 d-sm-inline-flex w-50"
                                  type="checkbox"
                                  id={`inline-checkbox-1`}
                                />
                              ))}
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>Aminities</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            <div key={`inline-checkbox`} className="mb-3">
                              {aminities.map((item, value) => (
                                <Form.Check
                                  label={item.name}
                                  name="propertyAmenityIds"
                                  value={item.id}
                                  onChange={(e) => selectAminitiesFilter(e)}
                                  className="mt-2 mr-0 d-sm-inline-flex w-50"
                                  type="checkbox"
                                  id={`inline-checkbox-1`}
                                />
                              ))}
                            </div>
                          </Form>
                        </div>
                      </div>
                      <div className="filter-list">
                        <p className="mb-0">
                          <b>No. of bedrooms</b>
                        </p>
                        <div className="listcheckbox">
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                  onChange={(e) => selectBedroomsFilter(e, 3)}
                                  value={3}
                                  checked={noofbadroom == 3 ? true : false}
                                  label="3"
                                  name="Three"
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-2`}
                                />
                                <Form.Check
                                  label="1"
                                  onChange={(e) => selectBedroomsFilter(e, 1)}
                                  checked={noofbadroom == 1 ? true : false}
                                  value={1}
                                  name="One"
                                  type={type}
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  id={`inline-${type}-3`}
                                />
                                <Form.Check
                                  label="4"
                                  onChange={(e) => selectBedroomsFilter(e, 4)}
                                  checked={noofbadroom == 4 ? true : false}
                                  value={4}
                                  name="Four"
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  label="2"
                                  onChange={(e) => selectBedroomsFilter(e, 2)}
                                  checked={noofbadroom == 2 ? true : false}
                                  value={2}
                                  name="Two"
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                                <Form.Check
                                  onChange={(e) => selectBedroomsFilter(e, 5)}
                                  checked={noofbadroom == 5 ? true : false}
                                  value={5}
                                  label="5+"
                                  name="Five"
                                  className="mt-2 w-50 mr-0 d-sm-inline-flex"
                                  type={type}
                                  id={`inline-${type}-1`}
                                />
                              </div>
                            ))}
                          </Form>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              )
              /*----------------------------------------Property , SeLL, lease Module End--------------------------------*/
            }

            <Col lg={8}>
              <div className="propertylist-area">
                <Row className="mb-3">
                  <Col lg={12}>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="default"
                        id="dropdown-basic"
                        className="border-1 rounded-1 h-auto border-secondary filterbtn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M1 3.99985L3.29289 1.70696C3.68342 1.31643 4.31658 1.31643 4.70711 1.70696L7 3.99985M4 1.99985L4 13.9998M10 12.9998L12.2929 15.2927C12.6834 15.6833 13.3166 15.6833 13.7071 15.2927L16 12.9998M13 14.9998V2.99985"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                        &nbsp; Sort by: Your category
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(e) =>
                            propertyPurchaseTypeId == 4
                              ? sortvehicleCategory(e, 1)
                              : sortByCategoryFilter(1)
                          }
                        >
                          Price: high to low
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) =>
                            propertyPurchaseTypeId == 4
                              ? sortvehicleCategory(e, 2)
                              : sortByCategoryFilter(2)
                          }
                        >
                          Price: low to high
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                {propertyPurchaseTypeId == 4 ? (
                  /*---------------------------------Vehicle Module Start-------------------------------------*/
                  <Row>
                    {vehiclelisting.length > 0 ? (
                      vehiclelisting.map((item) => {
                        return (
                          <Col lg={6} className="mb-4">
                            <div
                              className="propertybox "
                              onClick={(e) => gotovehicleDetail(e, item.id)}
                            >
                              <Swiper
                                pagination={{
                                  dynamicBullets: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                              >
                                <div
                                  className="overflow-hidden property-img"
                                  onClick={(e) => gotovehicleDetail(e, item.id)}
                                >
                                  <SwiperSlide>
                                    {/* <img src="assets/images/vacation_property.svg" /> */}
                                    {item.imageArr &&
                                      JSON.parse(item.imageArr).length > 0 ? (
                                      <LazyLoad width={416}>
                                        <img
                                          src={`${JSON.parse(item.imageArr)[0]
                                              .fileNameImage
                                            }`}
                                          className=""
                                        />
                                      </LazyLoad>
                                    ) : (
                                      ""
                                    )}
                                  </SwiperSlide>
                                </div>
                                <div className="heart-icon">
                                  {wishList.length > 0 &&
                                    wishList.find(
                                      (wishitem) =>
                                        wishitem.propertyId == item.id &&
                                        wishitem.status == 1 &&
                                        wishitem.userId == loginData?.id
                                    ) ? (
                                    <svg
                                      onClick={(e) =>
                                        addToWishList(e, item.id, 1, 0)
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="19"
                                      viewBox="0 0 22 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M2.67587 11.7115C2.63949 11.6795 2.60746 11.6514 2.5796 11.6269V11.5344L2.43198 11.3878C1.20735 10.1713 0.5 8.55715 0.5 6.84979V6.64011C0.605106 3.33023 3.44217 0.608727 6.78607 0.608727C7.34205 0.608727 8.02744 0.796121 8.68457 1.14762C9.32278 1.48899 9.89783 1.96574 10.2814 2.51826C10.5791 3.20646 11.5587 3.19766 11.839 2.49186C12.1669 1.90463 12.7333 1.40534 13.3766 1.04896C14.0334 0.685115 14.7165 0.5 15.2139 0.5C18.6571 0.5 21.3947 3.21142 21.5 6.63986V6.84979C21.5 8.67408 20.7874 10.2768 19.5838 11.3728L19.4204 11.5215V11.6052C19.3844 11.6348 19.3445 11.6679 19.3013 11.7041C19.132 11.8459 18.9014 12.0432 18.6256 12.2815C18.0735 12.7584 17.3335 13.405 16.5264 14.1116C16.2678 14.338 16.0024 14.5705 15.734 14.8056C14.3264 16.0387 12.8366 17.3438 11.8273 18.2097C11.3566 18.5968 10.6434 18.5968 10.1727 18.2097C8.96965 17.1776 7.05737 15.5222 5.43102 14.1103C4.61714 13.4037 3.8752 12.7584 3.32999 12.283C3.05735 12.0453 2.83416 11.8503 2.67587 11.7115Z"
                                        fill="#FF0000"
                                        fill-opacity="0.7"
                                        stroke="white"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={(e) =>
                                        addToWishList(e, item.id, 1, 1)
                                      }
                                      width="22"
                                      height="19"
                                      viewBox="0 0 22 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M22 6.63233C21.8905 2.93562 18.9353 0 15.2139 0C14.0099 0 12.1493 0.869814 11.3831 2.28326C11.2736 2.60944 10.8358 2.60944 10.7264 2.28326C9.85075 0.978541 8.0995 0.108727 6.78607 0.108727C3.17413 0.108727 0.109453 3.04435 0 6.63233V6.84979C0 8.69814 0.766169 10.4378 2.0796 11.7425C2.0796 11.7425 2.0796 11.7425 2.0796 11.8512C2.18905 11.9599 7.44279 16.5265 9.85075 18.5923C10.5075 19.1359 11.4925 19.1359 12.1493 18.5923C14.5572 16.5265 19.7015 11.9599 19.9204 11.8512C19.9204 11.8512 19.9204 11.8512 19.9204 11.7425C21.2338 10.5465 22 8.80687 22 6.84979V6.63233Z"
                                        fill="white"
                                        fill-opacity="0.7"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </Swiper>

                              <Row className="mt-2">
                                <Col lg={8}>
                                  <h6>
                                    <b>{item.name}</b>
                                  </h6>
                                </Col>
                                <Col lg={4} className="text-right">
                                  {item.engineArr &&
                                    item.engineArr.length > 0 ? (
                                    <span className="text-grey">
                                      {" "}
                                      {item.engineArr[0].name}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <Col lg={8}>
                                  <p className="small text-grey">
                                    {item.location}
                                  </p>
                                </Col>
                                <Col lg={4} className="text-right">
                                  <p
                                    style={{ color: "#FFB800" }}
                                    className="small"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M8.37531 0L10.2596 5.87336L16.3575 5.87336L11.4242 9.50329L13.3086 15.3766L8.37531 11.7467L3.44206 15.3766L5.32639 9.50329L0.393141 5.87336L6.49097 5.87336L8.37531 0Z"
                                        fill="#FFB800"
                                      />
                                    </svg>
                                    &nbsp;
                                    {!item.rating
                                      ? "0"
                                      : parseFloat(item.rating).toFixed(1)}
                                    {item.ratingArr == null
                                      ? "(0)"
                                      : `(${JSON.parse(item.ratingArr).length
                                      })`}
                                    {/* 4.5 (200) */}
                                  </p>
                                </Col>
                                <Col lg={8}>
                                  <small>
                                    <a
                                      href="#"
                                      className="text-decoration-none"
                                    >
                                      $ {item.perHourRate} / hour
                                    </a>
                                  </small>
                                </Col>
                                <Col lg={4} className="text-right">
                                  <p class="text-grey small">
                                    <span class="me-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 11 13"
                                        fill="none"
                                      >
                                        <path
                                          d="M10.0833 10.0455H9.76185L9.47215 4.72732C9.47103 3.74866 8.65093 2.95567 7.63881 2.95459H7.33333V2.36361H7.94441C8.28178 2.36328 8.55515 2.09895 8.55548 1.77273V1.18186C8.5547 0.529418 8.00797 0.000757394 7.33333 0H3.66667C2.99192 0.000757394 2.44519 0.529418 2.44441 1.18186V1.77273C2.44474 2.09895 2.71811 2.36328 3.05548 2.36361H3.66667V2.95459H3.36107C2.35455 2.95362 1.53591 3.73849 1.5283 4.71174L1.23804 10.0455H0.916667C0.410553 10.0461 0.000559482 10.4425 0 10.9319V11.2273C0.00111896 12.2059 0.821218 12.9989 1.83333 13H9.16667C10.1787 12.9989 10.9989 12.2059 11 11.2273V10.9319C10.9994 10.4425 10.5893 10.0461 10.0833 10.0455ZM3.05548 1.18186C3.05582 0.855638 3.32929 0.591199 3.66667 0.590875H7.33333C7.67071 0.591199 7.94407 0.855638 7.94441 1.18186V1.77273H3.05548V1.18186ZM4.27774 2.36361H6.72215V2.95459H4.27774V2.36361ZM2.13881 4.72732C2.1396 4.07488 2.68633 3.54622 3.36107 3.54546H7.63881C7.96544 3.54676 8.27798 3.6739 8.50748 3.89852C8.73698 4.12325 8.86443 4.42707 8.86163 4.7429L9.15033 10.0455H8.3854L8.10017 5.3182C8.09939 4.66576 7.55265 4.1371 6.87791 4.13634H4.12198C3.45339 4.13493 2.90845 4.6545 2.90039 5.30088L2.61449 10.0455H1.84967L2.13881 4.72732ZM7.77343 10.0455H3.22646L3.51091 5.3182C3.51124 4.99198 3.78461 4.72765 4.12198 4.72732H6.87802C7.04285 4.72851 7.20029 4.79354 7.31532 4.9078C7.43024 5.02216 7.49301 5.17613 7.48977 5.33551L7.77343 10.0455ZM10.3888 11.2273C10.388 11.8797 9.8413 12.4083 9.16667 12.4091H1.83333C1.15859 12.4083 0.611969 11.8797 0.611074 11.2273V10.9319C0.61141 10.7688 0.748037 10.6367 0.916667 10.6364H10.0833C10.252 10.6367 10.3885 10.7688 10.3888 10.9319V11.2273Z"
                                          fill="#838383"
                                        />
                                      </svg>
                                      &nbsp;{item.seats}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        );
                      })
                    ) : (
                      <Col lg={12} className="mb-4">
                        <div className="propertybox p-5 text-center border">
                          No Data Found
                        </div>
                      </Col>
                    )}
                  </Row>
                ) : (
                  /*---------------------------------Property , SeLL, lease Module Start-------------------------------------*/
                  <Row>
                    {listing.length > 0 ? (
                      listing.map((item) => {
                        return (
                          <Col lg={6} className="mb-4">
                            <div
                              className="propertybox "
                              onClick={(e) => redirectToDetailPage(e, item.id)}
                            >
                              <Swiper
                                pagination={{
                                  dynamicBullets: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                              >
                                <div
                                  className="overflow-hidden property-img"
                                  onClick={(e) =>
                                    redirectToDetailPage(e, item.id)
                                  }
                                >
                                  <SwiperSlide>
                                    {/* <img src="assets/images/vacation_property.svg" /> */}
                                    {item.imageArr &&
                                      JSON.parse(item.imageArr).length > 0 ? (
                                      <LazyLoad width={416}>
                                        <img
                                          src={`${JSON.parse(item.imageArr)[0]
                                              .fileNameImage
                                            }`}
                                          className=""
                                        />
                                      </LazyLoad>
                                    ) : (
                                      ""
                                    )}
                                  </SwiperSlide>
                                </div>
                                <div className="heart-icon">
                                  {wishList.find(
                                    (wishitem) =>
                                      wishitem.propertyId == item.id &&
                                      wishitem.status == 1 &&
                                      wishitem.userId == loginData?.id
                                  ) ? (
                                    <svg
                                      onClick={(e) =>
                                        addToWishList(e, item.id, 1, 0)
                                      }
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="19"
                                      viewBox="0 0 22 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M2.67587 11.7115C2.63949 11.6795 2.60746 11.6514 2.5796 11.6269V11.5344L2.43198 11.3878C1.20735 10.1713 0.5 8.55715 0.5 6.84979V6.64011C0.605106 3.33023 3.44217 0.608727 6.78607 0.608727C7.34205 0.608727 8.02744 0.796121 8.68457 1.14762C9.32278 1.48899 9.89783 1.96574 10.2814 2.51826C10.5791 3.20646 11.5587 3.19766 11.839 2.49186C12.1669 1.90463 12.7333 1.40534 13.3766 1.04896C14.0334 0.685115 14.7165 0.5 15.2139 0.5C18.6571 0.5 21.3947 3.21142 21.5 6.63986V6.84979C21.5 8.67408 20.7874 10.2768 19.5838 11.3728L19.4204 11.5215V11.6052C19.3844 11.6348 19.3445 11.6679 19.3013 11.7041C19.132 11.8459 18.9014 12.0432 18.6256 12.2815C18.0735 12.7584 17.3335 13.405 16.5264 14.1116C16.2678 14.338 16.0024 14.5705 15.734 14.8056C14.3264 16.0387 12.8366 17.3438 11.8273 18.2097C11.3566 18.5968 10.6434 18.5968 10.1727 18.2097C8.96965 17.1776 7.05737 15.5222 5.43102 14.1103C4.61714 13.4037 3.8752 12.7584 3.32999 12.283C3.05735 12.0453 2.83416 11.8503 2.67587 11.7115Z"
                                        fill="#FF0000"
                                        fill-opacity="0.7"
                                        stroke="white"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      onClick={(e) =>
                                        addToWishList(e, item.id, 1, 1)
                                      }
                                      width="22"
                                      height="19"
                                      viewBox="0 0 22 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M22 6.63233C21.8905 2.93562 18.9353 0 15.2139 0C14.0099 0 12.1493 0.869814 11.3831 2.28326C11.2736 2.60944 10.8358 2.60944 10.7264 2.28326C9.85075 0.978541 8.0995 0.108727 6.78607 0.108727C3.17413 0.108727 0.109453 3.04435 0 6.63233V6.84979C0 8.69814 0.766169 10.4378 2.0796 11.7425C2.0796 11.7425 2.0796 11.7425 2.0796 11.8512C2.18905 11.9599 7.44279 16.5265 9.85075 18.5923C10.5075 19.1359 11.4925 19.1359 12.1493 18.5923C14.5572 16.5265 19.7015 11.9599 19.9204 11.8512C19.9204 11.8512 19.9204 11.8512 19.9204 11.7425C21.2338 10.5465 22 8.80687 22 6.84979V6.63233Z"
                                        fill="white"
                                        fill-opacity="0.7"
                                      />
                                    </svg>
                                  )}
                                </div>
                              </Swiper>

                              <Row className="mt-2">
                                <Col lg={8} sm={8} xs={8}>
                                  <h6>
                                    {/* <b>{item.propertyName}</b> */}
                                    <b>
                                      {item?.propertyName?.length > 20
                                        ? `${item?.propertyName.slice(
                                          0,
                                          20
                                        )}...`
                                        : item?.propertyName}
                                    </b>
                                  </h6>
                                </Col>
                                <Col lg={4} sm={4} xs={4} className="text-right">
                                  <span className="text-grey">
                                    {item.PropertyType}
                                  </span>
                                </Col>
                                <Col lg={8} sm={8} xs={8}>
                                  <p className="small text-grey">
                                    {/* {item.location} */}

                                    {item.location?.length > 25
                                      ? `${item.location.slice(0, 15)}...`
                                      : item.location}
                                  </p>
                                </Col>
                                <Col lg={4} sm={4} xs={4} className="text-right">
                                  <p
                                    style={{ color: "#FFB800" }}
                                    className="small fw-bold d-flex justify-content-end align-items-center"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                    >
                                      <path
                                        d="M8.37531 0L10.2596 5.87336L16.3575 5.87336L11.4242 9.50329L13.3086 15.3766L8.37531 11.7467L3.44206 15.3766L5.32639 9.50329L0.393141 5.87336L6.49097 5.87336L8.37531 0Z"
                                        fill="#FFB800"
                                      />
                                    </svg>
                                    &nbsp;
                                    {item.rating == 0
                                      ? "0"
                                      : parseFloat(item.rating).toFixed(1)}
                                    {item.ratingArr == null
                                      ? "(0)"
                                      : `(${JSON.parse(item.ratingArr).length
                                      })`}
                                    {/* 4.5 (200) */}
                                  </p>
                                </Col>
                                <Col lg={8} sm={8} xs={8}>
                                  <small>
                                    <a
                                      href="#"
                                      className="text-decoration-none fw-bold"
                                    >
                                      $ {item.price}{" "}
                                      {propertyPurchaseTypeId == 2
                                        ? "/ Night"
                                        : ""}
                                    </a>
                                  </small>
                                </Col>

                                <Col lg={4} sm={4} xs={4} className="text-right">
                                  <p class="text-grey small">
                                    <span class="me-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 43 32"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.87247 4.81061C5.87247 4.06519 6.17849 3.3503 6.72322 2.82321C7.26795 2.29612 8.00677 2 8.77713 2H33.9509C34.7213 2 35.4601 2.29612 36.0048 2.82321C36.5495 3.3503 36.8556 4.06519 36.8556 4.81061V15.1162H5.87247V4.81061Z"
                                          stroke="#838383"
                                          stroke-width="3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M3.93668 26.3589V30.1064"
                                          stroke="#838383"
                                          stroke-width="3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M38.7922 26.3589V30.1064"
                                          stroke="#838383"
                                          stroke-width="3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M26.2057 10.4316H16.5235C15.7531 10.4316 15.0143 10.7278 14.4696 11.2549C13.9249 11.7819 13.6188 12.4968 13.6188 13.2423V15.116H29.1104V13.2423C29.1104 12.4968 28.8044 11.7819 28.2596 11.2549C27.7149 10.7278 26.9761 10.4316 26.2057 10.4316Z"
                                          stroke="#838383"
                                          stroke-width="3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M2 17.9268C2 17.1814 2.30603 16.4665 2.85076 15.9394C3.39549 15.4123 4.1343 15.1162 4.90466 15.1162H37.8242C38.5946 15.1162 39.3334 15.4123 39.8781 15.9394C40.4228 16.4665 40.7289 17.1814 40.7289 17.9268V26.3587H2V17.9268Z"
                                          stroke="#838383"
                                          stroke-width="3"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      &nbsp;{item.numberOfBeds}
                                    </span>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="20"
                                        viewBox="0 0 33 32"
                                        fill="none"
                                      >
                                        <path
                                          d="M30.8029 11.559H8.10604V6.60512C8.10604 4.78376 9.56026 3.30256 11.3485 3.30256C13.1366 3.30256 14.5909 4.78376 14.5909 6.60512H17.8333C17.8333 2.96239 14.9248 0 11.3485 0C7.77207 0 4.86362 2.96239 4.86362 6.60512V11.559H1.62121C1.19124 11.559 0.778876 11.7329 0.474841 12.0426C0.170805 12.3523 0 12.7723 0 13.2102V16.5128C0 20.816 2.7139 24.4769 6.48483 25.8425V31.3743H9.72725V26.4205H22.6969V31.3743H25.9393V25.8425C29.7103 24.4769 32.4242 20.816 32.4242 16.5128V13.2102C32.4242 12.7723 32.2534 12.3523 31.9493 12.0426C31.6453 11.7329 31.2329 11.559 30.8029 11.559ZM29.1817 16.5128C29.1817 20.1555 26.2733 23.1179 22.6969 23.1179H9.72725C6.15086 23.1179 3.24242 20.1555 3.24242 16.5128V14.8615H29.1817V16.5128Z"
                                          fill="#838383"
                                        />
                                      </svg>
                                      &nbsp;{item.numberOfBathroom}
                                    </span>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        );
                      })
                    ) : (
                      <Col lg={12} className="mb-4">
                        <div className="propertybox p-5 text-center border">
                          No Data Found
                        </div>
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default Propertylist;
