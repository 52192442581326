import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import "./App.css";
import config from "./config";
import ScrollToTop from "./coreFIles/scrolltotop";
import PageNotFound from "./generalPages/Pagenotfound";
import Home from "./generalPages/Home";
import Sitemap from "./generalPages/sitemap";
import DestinationDetails from "./generalPages/destinationDetails";
import SuggestionsDetails from "./generalPages/suggestionsDetails";
import Login from "./generalPages/login";
import Signup from "./generalPages/signup";
import AboutUs from "./generalPages/aboutUs";
import AboutUs2 from "./generalPages/aboutUs2";
import AboutUsForApp from "./generalPages/AboutUsForApp";
import AboutUsDetailForApp from "./generalPages/AboutUsDetailForApp";
import Contactus from "./generalPages/contactus";
import Blog from "./generalPages/blog";
import Blog2 from "./generalPages/blog2";
import Blog2ForApp from "./generalPages/Blog2ForApp";
import BlogDetailForApp from "./generalPages/BlogDetailForApp";
import Blogdetail from "./generalPages/blogdetail";
import PromiseDetail from "./generalPages/PromiseDetail";
import PromiseDetailForApp from "./generalPages/PromiseDetailForApp";
import PrivacyPolicy from "./generalPages/privacyPolicy";
import PrivacyPolicyForApp from "./generalPages/PrivacyPolicyforApp";
import Faq from "./generalPages/Faq";
import FaqForApp from "./generalPages/faqForApp";
import TermsConditions from "./generalPages/termsConditions";
import TermsConditionForApp from "./generalPages/termsConditionsForApp";
import Resetpassword from "./generalPages/resetpassword";
import Mobileverification from "./generalPages/mobileverification";
import Passwordsuccesfully from "./generalPages/passwordsuccesfullyreset";
import Forgetpassword from "./generalPages/forgetpassword";
import Subscriptionplan from "./generalPages/subscriptionplan";
import Seolanding from "./generalPages/seolanding";
import PropertyDetails from "./generalPages/propertyDetails";
import VehicleDetails from "./generalPages/vehicleDetails";
// -------------User Module Start---------------
import UserLogin from "./generalPages/usermodule/userlogin";
import UserSignup from "./generalPages/usermodule/usersignup";
import Userforgetpassword from "./generalPages/usermodule/userforgetpassword";
import Propertylist from "../src/afterLoggedin/commanComponents/propertylist";
import PropertyDetail from "../src/afterLoggedin/commanComponents/propertydetail";
import VehicleDetail from "../src/afterLoggedin/commanComponents/vehicledetail";
import Payment from "../src/afterLoggedin/commanComponents/payment";
import VehiclePayment from "../src/afterLoggedin/commanComponents/vehiclepayment";
import Thankyou from "../src/afterLoggedin/commanComponents/thankyou";
import PaymentSuccess from "../src/afterLoggedin/commanComponents/paymentSuccess";
import PaymentError from "../src/afterLoggedin/commanComponents/paymentError";
import Aboutusdetail from "./generalPages/AboutUsDetails";
import OurMission from "./generalPages/OurMission";
import OurVision from "./generalPages/OurVision";
import OurMissionForApp from "./generalPages/OurMissionForApp";
import OurVisionForApp from "./generalPages/OurVisionForApp";
import WhatWeOffer from "./generalPages/WhatWeOffer";
import WhatWeOfferForApp from "./generalPages/WhatWeOfferForApp";
import AccountDeletion from "./generalPages/Accountdeletion";
// -------------Staff Dashboard-----------------------
import Dashboard from "./afterLoggedin/staffComponents/Dashboard";
import Vehicle from "./afterLoggedin/staffComponents/Dashboard/Vehicle";
import Service from "./afterLoggedin/staffComponents/Dashboard/Service";
import Financedashboard from "./afterLoggedin/staffComponents/Dashboard/Finance";
import Profitloss from "./afterLoggedin/staffComponents/Dashboard/Finance/profitloss";
import Employee from "./afterLoggedin/staffComponents/Dashboard/Finance/employee";
import Addexpenses from "./afterLoggedin/staffComponents/Dashboard/Finance/addexpenses";
import Expenses from "./afterLoggedin/staffComponents/Dashboard/Finance/expenses";
import Supplier from "./afterLoggedin/staffComponents/Dashboard/Finance/supplier";
import Invoice from "./afterLoggedin/staffComponents/Dashboard/Finance/invoice";
import Receivable from "./afterLoggedin/staffComponents/Dashboard/Finance/receivable";
import Tax from "./afterLoggedin/staffComponents/Dashboard/Finance/tax";
import Cash from "./afterLoggedin/staffComponents/Dashboard/Finance/cash";
// -------------User Module End---------------
const PropertyOwner = React.lazy(() =>
  import("./afterLoggedin/userroute/PropertyOwner")
);
const UsersRoutes = React.lazy(() =>
  import("./afterLoggedin/userroute/UsersRoutes")
);
// const UsersVehicle = React.lazy(() => import('./afterLoggedin/userroute/UserVehicle'));
const Explorer = React.lazy(() => import("./afterLoggedin/userroute/Explorer"));

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path={`${config.baseurl}sitemap`} element={<Sitemap />} />
            <Route path={`${config.baseurl}`} element={<Home />} />
            <Route
              path={`${config.baseurl}paymentSuccess`}
              element={<PaymentSuccess />}
            />
            <Route
              path={`${config.baseurl}paymentError`}
              element={<PaymentError />}
            />
            <Route
              path={`${config.baseurl}destinationDetails/:id`}
              element={<DestinationDetails />}
            />
            <Route
              path={`${config.baseurl}propertyDetails/:id`}
              element={<PropertyDetails />}
            />
            <Route
              path={`${config.baseurl}suggestionsDetails/:id`}
              element={<SuggestionsDetails />}
            />
            <Route path={`vehicleDetails/:id`} element={<VehicleDetails />} />
            <Route
              path={`${config.baseurl}about`}
              element={<h1>Welcome to about page</h1>}
            />
            <Route path={`${config.baseurl}Signup`} element={<Signup />} />
            <Route path={`${config.baseurl}login`} element={<Login />} />
            <Route
              path={`${config.baseurl}forgetpassword`}
              element={<Forgetpassword />}
            />
            <Route
              path={`${config.baseurl}resetpassword`}
              element={<Resetpassword />}
            />
            <Route
              path={`${config.baseurl}mobileverification`}
              element={<Mobileverification />}
            />
            <Route
              path={`${config.baseurl}passwordsuccesfullyreset`}
              element={<Passwordsuccesfully />}
            />
            <Route
              path={`${config.baseurl}subscriptionplan`}
              element={<Subscriptionplan />}
            />
            <Route
              path={`${config.baseurl}accountDeletion`}
              element={<AccountDeletion />}
            />
            <Route
              path={`${config.baseurl}seolanding`}
              element={<Seolanding />}
            />
            <Route path={`${config.baseurl}thank-you`} element={<Thankyou />} />
            {/* <Route path={`${config.baseurl}aboutUs`} element={<AboutUs />} /> */}
            <Route path={`${config.baseurl}aboutUs`} element={<AboutUs2 />} />
            <Route
              path={`${config.baseurl}AboutUsData`}
              element={<AboutUsForApp />}
            />
            <Route
              path={`${config.baseurl}contactus`}
              element={<Contactus />}
            />
            {/* <Route path={`${config.baseurl}blog`} element={<Blog />} /> */}
            <Route path={`${config.baseurl}blog`} element={<Blog2 />} />
            <Route
              path={`${config.baseurl}BlogData`}
              element={<Blog2ForApp />}
            />
            <Route
              path={`${config.baseurl}BlogDetail/:id`}
              element={<BlogDetailForApp />}
            />
            <Route
              path={`${config.baseurl}blog_detail/:id`}
              element={<Blogdetail />}
            />
            <Route
              path={`${config.baseurl}aboutus-detail`}
              element={<Aboutusdetail />}
            />
            <Route
              path={`${config.baseurl}aboutUsDetail`}
              element={<AboutUsDetailForApp />}
            />
            <Route
              path={`${config.baseurl}ourMission`}
              element={<OurMission />}
            />
            <Route
              path={`${config.baseurl}OurMissionDetail`}
              element={<OurMissionForApp />}
            />
            <Route
              path={`${config.baseurl}ourVision`}
              element={<OurVision />}
            />
            <Route
              path={`${config.baseurl}OurVisionDetail`}
              element={<OurVisionForApp />}
            />
            <Route
              path={`${config.baseurl}whatWeOffer/:id`}
              element={<WhatWeOffer />}
            />
            <Route
              path={`${config.baseurl}WhatWeOfferDetail/:id`}
              element={<WhatWeOfferForApp />}
            />
            <Route
              path={`${config.baseurl}promiseDetail/:id`}
              element={<PromiseDetail />}
            />
            <Route
              path={`${config.baseurl}PromiseDetails/:id`}
              element={<PromiseDetailForApp />}
            />
            <Route
              path={`${config.baseurl}privacyPolicy`}
              element={<PrivacyPolicy />}
            />
            <Route
              path={`${config.baseurl}PrivacyPolicyData`}
              element={<PrivacyPolicyForApp />}
            />
            <Route path={`${config.baseurl}faq`} element={<Faq />} />
            <Route
              path={`${config.baseurl}faqForApp`}
              element={<FaqForApp />}
            />
            <Route
              path={`${config.baseurl}termsConditions`}
              element={<TermsConditions />}
            />
            <Route
              path={`${config.baseurl}TermsConditionData`}
              element={<TermsConditionForApp />}
            />

            {/* // -------------User Module Start--------------- */}
            <Route
              path={`${config.baseurl}usersignup`}
              element={<UserSignup />}
            />
            <Route
              path={`${config.baseurl}userlogin`}
              element={<UserLogin />}
            />
            <Route
              path={`${config.baseurl}userforgetpassword`}
              element={<Userforgetpassword />}
            />
            <Route
              path={`${config.baseurl}propertylist/:location/:numberOfAdults/:checkin/:checkout/:numberOfChildren/:numberOfInfants/:numberOfPets/:propertyPurchaseTypeId`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <Propertylist />{" "}
                </Suspense>
              }
            />
            <Route
              path={`${config.baseurl}propertydetail/:id/:numberOfAdults/:checkin/:checkout/:numberOfChildren/:numberOfInfants/:numberOfPets/:propertyPurchaseTypeId/:roomType`}
              element={<PropertyDetail />}
            />
            <Route
              path={`${config.baseurl}vehicledetail/:id/:propertyPurchaseTypeId/:checkin/:checkout`}
              element={<VehicleDetail />}
            />
            <Route
              path={`${config.baseurl}payment/:id/:numberOfAdults/:checkin/:checkout/:numberOfChildren/:numberOfInfants/:numberOfPets/:roomType`}
              element={<Payment />}
            />
            <Route
              path={`${config.baseurl}vehiclepayment/:id/:checkin/:checkout/:passenger/`}
              element={<VehiclePayment />}
            />

            {/* // -------------User Module End--------------- */}
            <Route
              path={`${config.baseurl}${config.loginurl}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <PropertyOwner />{" "}
                </Suspense>
              }
            />

            <Route
              path={`${config.baseurl}${config.loginurluser}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <UsersRoutes />{" "}
                </Suspense>
              }
            />
            {/* <Route path={`${config.baseurl}${config.uservehicle}/*`} element={<Suspense fallback={<div>Loading...</div>}> <UsersVehicle /> </Suspense>} /> */}
            <Route
              path={`${config.baseurl}${config.explorer}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {" "}
                  <Explorer />{" "}
                </Suspense>
              }
            />
            <Route path={`/*`} element={<PageNotFound />} />

            {/* // -------------Staff Components Start--------------- */}

            <Route
              path={`${config.baseurl}staffdashboard`}
              element={<Dashboard />}
            />
            <Route
              path={`${config.baseurl}vehicledashboard`}
              element={<Vehicle />}
            />
            <Route
              path={`${config.baseurl}servicedashboard`}
              element={<Service />}
            />
            <Route
              path={`${config.baseurl}financedashboard`}
              element={<Financedashboard />}
            />
            <Route
              path={`${config.baseurl}profitloss`}
              element={<Profitloss />}
            />
            <Route
              path={`${config.baseurl}employee`}
              element={<Employee />}
            />
            <Route
              path={`${config.baseurl}addexpenses`}
              element={<Addexpenses />}
            />
            <Route
              path={`${config.baseurl}expenses`}
              element={<Expenses />}
            />
            <Route
              path={`${config.baseurl}supplier`}
              element={<Supplier />}
            />
            <Route
              path={`${config.baseurl}invoice`}
              element={<Invoice />}
            />
            <Route path={`${config.baseurl}receivable`} element={<Receivable />} />
            <Route path={`${config.baseurl}tax`} element={<Tax />} />
            <Route path={`${config.baseurl}cash`} element={<Cash />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
