import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Piechart = () => {
	const options = {
		animationEnabled: true,
	
		subtitles: [{
			verticalAlign: "center",
			fontSize: 24,
			dockInsidePlotArea: true
		}],
		data: [{
			type: "doughnut",
			showInLegend: true,
			yValueFormatString: "#,###'%'",
			dataPoints: [
				{ name: "Open Invoices", y: 50,color: "#F7BB00" },
				{ name: "Overdue Invoices", y: 18, color: "#E46422" },
                { name: "Paid in last 30 days", y: 12, color: "#14B8A6" }
			]
		}]
	};

	return (
		<div>
			<CanvasJSChart options={options} containerProps={{ width: '100%', height: '236px' }}/>
		</div>
	);
};

export default Piechart;
