import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Table, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../../../../config";
import config1 from "../../../../coreFIles/config";
import { FaPlus,FaArrowLeftLong } from "react-icons/fa6";
import { BsArrowLeft } from "react-icons/bs";
import Header from "../../directives/Header"
import "../../css/staffdashboard.css"
import "../../css/finance.css"

import ListMenuButton from "./listMenu";
const Invoice = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    const navigateRoute = () => {
        setTimeout(() => {
            navigate(`${config.baseurl}${config.loginurl}`, { state: "prop" });
            window.location.reload();
        });
    };

    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };

    const columns = [
        {
            name: 'Product Service',
            selector: row => row.ProductService,
        },
        {
            name: 'Description',
            selector: row => row.Description,
        },
        {
            name: 'Qty',
            selector: row => row.Qty,
        },
        {
            name: 'Rate',
            selector: row => row.Rate,
        },
        {
            name: 'Amount',
            selector: row => row.Amount,
        },


    ];

    const data = [
        {
            id: 1,
            ProductService: 'Oven Repair',
            Description: 'oven repair',
            Qty: '10',
            Rate: '$80.50',
            Amount: '$800.50',

        },
        {
            id: 2,
            ProductService: 'Fridge Repair',
            Description: 'Fridge Repair',
            Qty: '05',
            Rate: '$90.56',
            Amount: '$450.96',

        },

    ]


    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>

                    <Row className="">
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <Button variant="default" className="p-0 h-auto"><FaArrowLeftLong size={`25px`}/></Button>
                               
                            </div>

                        </Col>
                        <Col lg={7} sm={12} xs={12} className=" text-right">
                           
                        </Col>
                    </Row>

                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3 pt-sm-4 px-sm-5">
                                <Card.Header className="border-0 mb-0 px-sm-5">
                                    <div className=""><h4 className="mb-0 fw-bolder">Invoice </h4></div>
                                </Card.Header>
                                <Card.Body className="p-0 px-sm-5">
                                    <div className="">
                                        <Row className="mt-2">
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Customer Name</Form.Label>
                                                    <Form.Control type="text" placeholder="" value={`Mark Smith`} className="rounded-5" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Email</Form.Label>
                                                    <Form.Control type="email" value="marksmith@gmail.com" className="rounded-5" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Invoice Date </Form.Label>
                                                    <Form.Control type="date" placeholder="" className="rounded-5" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Due Date </Form.Label>
                                                    <Form.Control type="date" placeholder="" className="rounded-5" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Terms </Form.Label>
                                                    <Form.Control type="text" placeholder="Write here" className="rounded-5" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Invoice Number </Form.Label>
                                                    <Form.Control type="number" placeholder="#5465131" className="rounded-5" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Billing Address </Form.Label>
                                                    <Form.Control as="textarea" type="text" placeholder="Write here" rows={5} className="rounded-4 h-auto" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Tags </Form.Label>
                                                    <Form.Control as="textarea" type="text" placeholder="Write here" rows={5} className="rounded-4 h-auto" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} xs={12}>
                                                <div className="mb-4">
                                                    <DataTable
                                                        columns={columns}
                                                        data={data}
                                                        customStyles={customStyles}
                                                        theme="solarized"
                                                    />
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className="">
                                            <Col lg={8} md={6} className="mb-4 text-start">
                                                <Button
                                                    variant="outline-primary"
                                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-3 mobileBtn"
                                                >
                                                    <FaPlus /> &nbsp;Add New
                                                </Button>
                                            </Col>
                                            <Col lg={4} md={6} className="mb-4">
                                                <Row>
                                                    <Col lg={6} sm={6} xs={6} className="mb-3"><p className="mb-0 text-black fw-medium">Sub Total</p></Col>
                                                    <Col lg={6} sm={6} xs={6} className="mb-3 text-end"><h5 className="fw-bold mb-0">$1251.46</h5></Col>
                                                    <Col lg={6} sm={6} xs={6} className="mb-3"><p className="mb-0 text-black fw-medium">VAT</p></Col>
                                                    <Col lg={6} sm={6} xs={6} className="mb-3 text-end"><h5 className="fw-bold mb-0">$45.56</h5></Col>
                                                    <Col lg={6} sm={6} xs={6} className="mb-3"><p className="mb-0 text-black fw-medium">Total</p></Col>
                                                    <Col lg={6} sm={6} xs={6} className="mb-3 text-end"><h5 className="fw-bold mb-0">$1301.46</h5></Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="text-center mb-4">
                                                <div>
                                                    <Link to={`#`}>
                                                        <Button
                                                            variant="outline-primary"
                                                            className=" rounded-5 me-2 px-4 px-xs-1 mb-3 mobileBtn"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                    <Link to={`#`}>
                                                        <Button
                                                            variant="primary"
                                                            className=" rounded-5 me-2 px-4 px-xs-1 mb-3 mobileBtn"
                                                        >
                                                            Send Quotation
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Message On Involve  </Form.Label>
                                                    <Form.Control as="textarea" type="text" placeholder="Write here" rows={5} className="rounded-4 h-auto" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6} className="mb-4">
                                                <Form.Group className="" controlId="formBasicEmail">
                                                    <Form.Label className="text-light-grey mb-1">Message On Statements  </Form.Label>
                                                    <Form.Control as="textarea" type="text" placeholder="Write here" rows={5} className="rounded-4 h-auto" />
                                                    {/* <Form.Text className="text-muted">
                                                                    We'll never share your email with anyone else.
                                                                </Form.Text> */}
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>



                </Container>
            </section>
        </div >
    );
};

export default Invoice;
