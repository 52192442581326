import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Header from '../afterLoggedin/commanComponents/usernavbar';
import "../../src/afterLoggedin/commanComponents/componentCSS/addlisting.css";
import "../../src/afterLoggedin/commanComponents/componentCSS/listingproperty.css";
import {
  getVehicleDetailAction,
  changeVehicleStatusAction,
} from "../Action/user.action";
import { toast, Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { Pagination } from "swiper/modules";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../src/afterLoggedin/commanComponents/componentCSS/swiper.css";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
const loginData = (!Cookies.get('loginSuccessPropertyOwner')) ? [] : JSON.parse(Cookies.get('loginSuccessPropertyOwner'));

const PropertyDetails = () => {
  const { id } = useParams();
  const [listing, setlisting] = useState([]);
  const [imagesArr, setimagesArr] = useState([]);
  const [vehicleFeatures, setVehicleFeatures] = useState([]);
  const [vehicleMore, setVehicleMore] = useState([]);

  useEffect(() => {
    getListingDetails();
  }, []);

  const getListingDetails = async () => {
    let res = await getVehicleDetailAction({ id: id });
    if (res.success) {

      if (parseInt(loginData?.id) != parseInt(res.data?.ownerId)) {
        window.location.href = `${config.baseUrl}dashboard/listingVehicle`
      }

      setlisting(res.data);
      if (res.data.vehicleImages) {
        setimagesArr(JSON.parse(res.data.vehicleImages));
      }
      setVehicleFeatures(res.data.vehicleFeatures);
      setVehicleMore(res.data.vehicleMore);
    }
  };

  const changeVehicleStatus = async (status) => {
    if (listing?.status == 0) {
      toast.error(
        "Your vehicle is under review. Please wait for platform approval"
      );
      return;
    } else if (listing?.status == 2) {
      toast.error("Your vehicle has been rejected from the platform.");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await changeVehicleStatusAction({ id: id, listingStatus: status });
        if (res.success) {
          toast.success(
            status == 1
              ? "Vehicle activated successfully"
              : "Vehicle deactivated successfully"
          );
          getListingDetails([]);
        } else {
          toast.error(res.msg);
        }
      }
    });
  };

  return (
    <div>
      <Header />
      <section className="propertydetail  pt-0 pb-0 overflow-hidden">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {imagesArr.length > 0
            ? imagesArr.map((data) => (
              <SwiperSlide>
                <div>
                  <img src={data.fileNameImage} />
                </div>
              </SwiperSlide>
            ))
            : ""}
        </Swiper>
        <Container className="mt-5">
          <Toaster />
          <Row className="justify-space-between">
            <Col xl={9} lg={8} mb={8} sm={8}>
              <h3 className="mb-3">
                <b>{listing.name}</b>
              </h3>
              <p className="mb-3">Location : {listing.location}</p>
              <p className="mb-3">Vehicle Number: {listing.vehicleNo}</p>
              <p className="mb-3">Price (Per Hour): ${listing.perHourRate}</p>
            </Col>
            <Col xl={3} lg={4} mb={4} sm={4} className="">
              {listing?.listingStatus == 1 ? (
                <Button
                  onClick={() => changeVehicleStatus(0)}
                  variant="primary"
                  className="rounded-2 h-auto mb-3"
                >
                  Deactivate Vehicle
                </Button>
              ) : (
                <Button
                  onClick={() => changeVehicleStatus(1)}
                  variant="primary"
                  className="rounded-2 h-auto mb-3"
                >
                  Activate Vehicle
                </Button>
              )}

              {listing?.listingStatus == 1 ? (
                <h6 className="text-success mb-3">
                  <b>Activated</b>
                </h6>
              ) : (
                <h6 className="text-danger mb-3">
                  <b>Deactivated</b>
                </h6>
              )}

              <h6 className="text-grey mb-3">
                Distance Covered: {listing.distanceCovered} Km{" "}
              </h6>

              <h6 className="text-grey mb-3">
                Trip Duration: {listing.minimumTripDuration} Days -{" "}
                {listing.maximumTripDuration} Days
              </h6>

              <h6 className="text-grey mb-3">
                Number Of Seats: {listing.seats}{" "}
              </h6>
            </Col>
          </Row>
        </Container>
        <hr />

        <div className="property-content mt-3">
          <Container>
            <Row>
      
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Description</b>
                  </h5>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: listing.description
                          ? listing.description
                          : "Not Filled!!",
                      }}
                    />
                  </p>
                </div>
              </Col>

              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Terms</b>
                  </h5>

                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: listing.terms ? listing.terms : "Not Filled!!",
                      }}
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <hr />

        <div className="offered_amenities mt-4 mb-3">
          <Container>
            <Row>
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Features</b>
                  </h5>
                  <Row>
                    {vehicleFeatures.length > 0 ? (
                      vehicleFeatures.map((data) => (
                        <Col lg={4}>
                          <div className="">
                            <p>{data.name}</p>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <span className="mb-3">Not Filled!!</span>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <hr />
        <div className="offered_amenities mt-4 mb-3">
          <Container>
            <Row>
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Engine Types</b>
                  </h5>
                  <Row>
                    {vehicleMore.length > 0 ? (
                      vehicleMore.map((data) => (
                        <Col lg={4}>
                          <div className="">
                            <p>{data.name}</p>
                          </div>
                        </Col>
                      ))
                    ) : (
                      <span className="mb-3">Not Filled!!</span>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <hr />

        <div className="offered_amenities mt-4 mb-3">
          <Container>
            <Row>
            {!listing.vehicleVideo ||
                        listing.vehicleVideo == null ||
                        listing.vehicleVideo == undefined ? (
                      ""
                      ) : (
              <Col lg={12} className="mb-3">
                <div>
                  <h5 className="mb-3">
                    <b>Video</b>
                  </h5>
                  <Row>
                    <Col lg="6">
                  
                        <>
                          <div className="propertyVideo">
                            <video width="100px" controls>
                              <source src={listing.vehicleVideo} />
                            </video>
                            <br />
                            <br />
                            <a href={listing.vehicleVideo} target="_blank">
                              <Button
                                variant="primary"
                                className="rounded-2 h-auto mb-3"
                              >
                                View Video
                              </Button>
                            </a>
                          </div>
                        </>
                   
                    </Col>
                  </Row>
                </div>
              </Col>
                 )}
            </Row>
          </Container>
        </div>
      </section>
    </div>
  );
};

export default PropertyDetails;
